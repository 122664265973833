<template>
<v-row justify="center">
<v-dialog v-model="dialog_local" fullscreen>
    <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('edit')" lazy-validation>
        <v-card class="mx-auto" tile>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
            {{readonly ? 'View' : 'Update'}} Car Park {{modalInfo.CarParkID}}
            <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
            </v-card-title>
        <v-card-text>
        <v-overlay :value="modal_loader">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-container class="fill-height"
        fluid >
            <v-row>
                <v-col cols="12" md="12">
                    <v-sheet style="border: 5px solid black" class="pa-5" color="white lighten-3">
                        <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                            <span class="headline">Main Info</span>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-divider class="my-4"></v-divider>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                            <v-text-field
                                dense
                                outlined
                                v-model="form[1].carparkname"
                                :rules="form[1].rule"
                                label="Car Park Name"
                                required
                                :disabled="readonly"
                            ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                            <v-autocomplete
                                dense
                                v-model="form[35].selected"
                                :items="form[35].carparkoperator"
                                :rules="form[35].rule"
                                item-text="name"
                                item-value="OperatorID"
                                label="Select Operator"
                                outlined
                                required
                                :disabled="readonly"
                            ></v-autocomplete>
                            </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                    dense
                                    type="time"
                                    outlined
                                    v-model="form[4].carparkoperationstarthour"
                                    :rules="form[4].rule"
                                    label="Operating Start Hour"
                                    :disabled="readonly"
                                ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                    dense
                                    type="time"
                                    outlined
                                    v-model="form[5].carparkoperationendhour"
                                    :rules="form[5].rule"
                                    label="Operating End Hour"
                                    :disabled="readonly"
                                ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                    dense
                                    outlined
                                    v-model="form[11].carparkemail"
                                    :rules="form[11].rule"
                                    label="Car Park Email"
                                    :disabled="readonly"
                                ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                    dense
                                    outlined
                                    v-model="form[12].carparkweburl"
                                    :rules="form[12].rule"
                                    label="Car Park Website"
                                    :disabled="readonly"
                                ></v-text-field>
                                </v-col>
                            <!--
                            <v-autocomplete
                                dense
                                v-model="form[0].selected"
                                :items="form[0].country"
                                :rules="form[0].rule"
                                item-text="name"
                                item-value="CountryCode"
                                label="Select Country"
                                outlined
                            ></v-autocomplete>
                            -->
                            <v-col cols="12" sm="12" md="6">
                            <v-text-field
                                dense
                                outlined
                                v-model="form[13].carparktelephoneno1"
                                :rules="form[13].rule"
                                label="Car Park Phone 1"
                                :disabled="readonly"
                            ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                            <v-text-field
                                dense
                                outlined
                                v-model="form[14].carparktelephoneno2"
                                :rules="form[14].rule"
                                label="Car Park Phone 2"
                                :disabled="readonly"
                            ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                            <v-text-field
                                dense
                                outlined
                                v-model="form[15].carparkfaxno"
                                :rules="form[15].rule"
                                label="Car Park Fax No."
                                :disabled="readonly"
                            ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                dense
                                outlined
                                type="number"
                                v-model="form[6].carparktotalbaycount"
                                :rules="form[6].rule"
                                label="Total Bay Count"
                                :disabled="readonly"
                            ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                dense
                                outlined
                                type="number"
                                v-model="form[22].carparktotalbayavailable"
                                :rules="form[22].rule"
                                label="Total Bay Available"
                                :disabled="readonly"
                            ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                dense
                                outlined
                                v-model="form[52].carparkuserinputcode"
                                :rules="form[52].rule"
                                label="Ref Code"
                                :disabled="readonly"
                            ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-autocomplete
                                    dense
                                    v-model="form[58].selected"
                                    :items="form[58].carparktype"
                                    :rules="form[58].rule"
                                    item-text="name"
                                    item-value="id"
                                    label="Select Car Park Type"
                                    outlined
                                    required
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <span class="text-body-2">Car Park Description</span>
                                <text-editor v-if="componentloader" v-model="form[57].carparkdescription" :value="form[57].carparkdescription" :style="readonly ? {'pointer-events': 'none'} : ''"></text-editor>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-if="componentloader">
                                <v-file-input label="Car Park Logo Image" v-model="form[53].file" :rules="form[53].imgrule" show-size type="file" accept="image/*" :disabled="readonly"></v-file-input>
                                <v-card class="mb-6" style="height: 200px" outlined @drop.prevent="addDropFile53" @dragover.prevent>
                                    <v-overlay
                                    :absolute="true"
                                    opacity="1"
                                    color="grey"
                                    :value="form[53].image == '' || form[53].image == undefined ? true : false"
                                    >
                                    <v-row>
                                        <v-col>
                                            <v-icon x-large>fa fa-image</v-icon>
                                        </v-col>
                                    </v-row>
                                    </v-overlay>
                                    <img :src="form[53].image" :style="{width: '200px'}" />
                                    <v-overlay
                                    :absolute="true"
                                    :value="form[53].btn_loading"
                                    >
                                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                                    </v-overlay>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <span class="text-body-2">Car Park Status</span>
                                <v-switch
                                color="success"
                                class="col-1 pl-0"
                                v-model="form[50].carparkactivestatus"
                                :label="`${form[50].carparkactivestatus ? 'Enabled' : 'Disabled'}`"
                                :disabled="readonly"
                                ></v-switch>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <span class="text-body-2">Show on Mobile App?</span>
                                <v-switch
                                color="success"
                                class="col-1 pl-0"
                                v-model="form[51].carparkmobiledisplaystatus"
                                :label="`${form[51].carparkmobiledisplaystatus ? 'Yes' : 'No'}`"
                                :disabled="readonly"
                                ></v-switch>
                            </v-col>
                        </v-row>
                  </v-sheet>
                </v-col>

                <v-col cols="12" md="12">
                    <v-sheet style="border: 5px solid black" class="pa-5" color="white lighten-3">
                        <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                            <span class="headline">Person in Charge</span>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-divider class="my-4"></v-divider>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                            <v-text-field
                                dense
                                outlined
                                v-model="form[7].carparkcontactpersonname"
                                :rules="form[7].rule"
                                label="Contact Person"
                                :disabled="readonly"
                            ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                            <v-text-field
                                dense
                                outlined
                                v-model="form[10].carparkcontactpersonemail"
                                :rules="form[10].rule"
                                label="Email"
                                :disabled="readonly"
                            ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                            <v-text-field
                                dense
                                outlined
                                v-model="form[8].carparksoscontact1"
                                :rules="form[8].rule"
                                label="Phone No. 1"
                                :disabled="readonly"
                            ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                            <v-text-field
                                dense
                                outlined
                                v-model="form[9].carparksoscontact2"
                                :rules="form[9].rule"
                                label="Phone No. 2"
                                :disabled="readonly"
                            ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
                <v-col cols="12" md="12">
                    <v-sheet style="border: 5px solid black" class="pa-5" color="white lighten-3">
                        <v-row dense>
                        <v-col cols="12" sm="12" md="12">
                            <span class="headline">Car Park Address</span>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                                <v-divider class="my-4"></v-divider>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field
                                dense
                                outlined
                                v-model="form[16].carparkaddress1"
                                :rules="form[16].rule"
                                label="Address 1"
                                :disabled="readonly"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field
                                dense
                                outlined
                                v-model="form[17].carparkaddress2"
                                :rules="form[17].rule"
                                label="Address 2"
                                :disabled="readonly"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field
                                dense
                                outlined
                                v-model="form[18].carparkaddress3"
                                :rules="form[18].rule"
                                label="Address 3"
                                :disabled="readonly"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-text-field
                                dense
                                outlined
                                v-model="form[19].carparkaddresspostcode"
                                :rules="form[19].rule"
                                label="Postcode"
                                @keyup="execFilter('postcode1')"
                                :disabled="readonly"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-autocomplete
                                dense
                                v-model="form[20].selected"
                                :items="form[20].carparkaddresscity"
                                :rules="form[20].rule"
                                item-text="name"
                                item-value="CityCode"
                                label="Select City"
                                @change="execFilter('state1')"
                                outlined
                                disabled
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-autocomplete
                                dense
                                v-model="form[21].selected"
                                :items="form[21].carparkaddressstate"
                                :rules="form[21].rule"
                                item-text="name"
                                item-value="StateCode"
                                label="Select State"
                                outlined
                                disabled
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-autocomplete
                                dense
                                v-model="form[0].selected"
                                :items="form[0].carparkaddresscountry"
                                :rules="form[0].rule"
                                item-text="name"
                                item-value="CountryCode"
                                label="Select Country"
                                outlined
                                disabled
                            ></v-autocomplete>
                        </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
                <v-col cols="12" md="12">
                    <v-sheet style="border: 5px solid black" class="pa-5" color="white lighten-3">
                        <v-row dense>
                        <v-col cols="12" sm="12" md="12">
                            <span class="headline">Car Park Billing Setup <span class="text-caption">This info will appear on Parking License, Invoices, CN, DN and OR.</span></span>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                                <v-divider class="my-4"></v-divider>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field
                                dense
                                outlined
                                v-model="form[23].carparkbillingcompanyname"
                                :rules="form[23].rule"
                                label="Company Name"
                                hint="Bills Payable To Company Name."
                                persistent-hint
                                :disabled="readonly"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-text-field
                                dense
                                outlined
                                v-model="form[24].carparkbillingsstno"
                                :rules="form[24].rule"
                                label="SST Reg No."
                                :disabled="readonly"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-text-field
                                dense
                                outlined
                                v-model="form[25].carparkbillingregno"
                                :rules="form[25].rule"
                                label="Reg No."
                                :disabled="readonly"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field
                                dense
                                outlined
                                v-model="form[26].carparkbillingaddress1"
                                :rules="form[26].rule"
                                label="Address 1"
                                :disabled="readonly"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field
                                dense
                                outlined
                                v-model="form[27].carparkbillingaddress2"
                                :rules="form[27].rule"
                                label="Address 2"
                                :disabled="readonly"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field
                                dense
                                outlined
                                v-model="form[28].carparkbillingaddress3"
                                :rules="form[28].rule"
                                label="Address 3"
                                :disabled="readonly"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-text-field
                                dense
                                outlined
                                v-model="form[29].carparkbillingaddresspostcode"
                                :rules="form[29].rule"
                                label="Postcode"
                                @keyup="execFilter('postcode2')"
                                :disabled="readonly"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-autocomplete
                                dense
                                v-model="form[30].selected"
                                :items="form[30].carparkbillingaddresscity"
                                :rules="form[30].rule"
                                item-text="name"
                                item-value="CityCode"
                                label="Select City"
                                @change="execFilter('state2')"
                                outlined
                                disabled
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-autocomplete
                                dense
                                v-model="form[31].selected"
                                :items="form[31].carparkbillingaddressstate"
                                :rules="form[31].rule"
                                item-text="name"
                                item-value="StateCode"
                                label="Select State"
                                outlined
                                disabled
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-autocomplete
                                dense
                                v-model="form[32].selected"
                                :items="form[32].carparkbillingaddresscountry"
                                :rules="form[32].rule"
                                item-text="name"
                                item-value="CountryCode"
                                label="Select Country"
                                outlined
                                disabled
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-text-field
                                dense
                                outlined
                                v-model="form[33].carparkbillingtelephoneno"
                                :rules="form[33].rule"
                                label="Telephone"
                                :disabled="readonly"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-text-field
                                dense
                                outlined
                                v-model="form[34].carparkbillingfaxno"
                                :rules="form[34].rule"
                                label="Fax No."
                                :disabled="readonly"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-text-field
                                dense
                                outlined
                                v-model="form[36].carparkbillingemail"
                                :rules="form[36].rule"
                                label="Email"
                                :disabled="readonly"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-text-field
                                dense
                                outlined
                                v-model="form[37].carparkbillingweburl"
                                :rules="form[37].rule"
                                label="Website"
                                :disabled="readonly"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" v-if="componentloader">
                                <v-file-input label="Car Park Header Image" v-model="form[54].file" :rules="form[54].imgrule" show-size type="file" accept="image/*" :disabled="readonly"></v-file-input>
                                <v-card class="mb-6" style="height: 200px" outlined @drop.prevent="addDropFile54" @dragover.prevent>
                                    <v-overlay
                                    :absolute="true"
                                    opacity="1"
                                    color="grey"
                                    :value="form[54].image == '' || form[54].image == undefined ? true : false"
                                    >
                                    <v-row>
                                        <v-col>
                                            <v-icon x-large>fa fa-image</v-icon>
                                        </v-col>
                                    </v-row>
                                    </v-overlay>
                                    <img :src="form[54].image" :style="{width: '100%'}" />
                                    <v-overlay
                                    :absolute="true"
                                    :value="form[54].btn_loading"
                                    >
                                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                                    </v-overlay>
                                </v-card>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" v-if="componentloader">
                                <v-file-input label="Car Park Footer Image" v-model="form[55].file" :rules="form[55].imgrule" show-size type="file" accept="image/*" :disabled="readonly"></v-file-input>
                                <v-card class="mb-6" style="height: 300px" outlined @drop.prevent="addDropFile55" @dragover.prevent>
                                    <v-overlay
                                    :absolute="true"
                                    opacity="1"
                                    color="grey"
                                    :value="form[55].image == '' || form[55].image == undefined ? true : false"
                                    >
                                    <v-row>
                                        <v-col>
                                            <v-icon x-large>fa fa-image</v-icon>
                                        </v-col>
                                    </v-row>
                                    </v-overlay>
                                    <img :src="form[55].image" :style="{width: '100%'}" />
                                    <v-overlay
                                    :absolute="true"
                                    :value="form[55].btn_loading"
                                    >
                                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                                    </v-overlay>
                                </v-card>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <span class="text-body-2">Terms & Conditions</span>
                            <text-editor v-if="componentloader" v-model="form[38].carparkbillingtermsconditions" :value="form[38].carparkbillingtermsconditions" :style="readonly ? {'pointer-events': 'none'} : ''"></text-editor>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="mt-4">
                                <v-combobox
                                v-model="form[49].selected"
                                :items="form[49].carparkpaymentmethod"
                                label="Payment Method"
                                multiple
                                item-text="name"
                                item-value="id"
                                outlined
                                :rules="form[49].rule"
                                dense
                                required
                                :disabled="readonly"
                                ></v-combobox>
                        </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
                <v-col cols="12" md="12">
                  <v-sheet style="border: 5px solid black" class="pa-5" color="white lighten-3">
                        <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                            <span class="headline">Geolocation</span>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-divider class="my-4"></v-divider>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    dense
                                    outlined
                                    v-model="form[2].latitude"
                                    :rules="form[2].rule"
                                    label="Latitude"
                                    disabled
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                dense
                                outlined
                                v-model="form[3].longitude"
                                :rules="form[3].rule"
                                label="Longitude"
                                disabled
                            ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <gmap-autocomplete
                                :value="this.form[39].carparklocation"
                                :class="[`search-textfield v-input__slot border-vc`]"
                                placeholder="Search Location"
                                :disabled="readonly"
                                @place_changed="setPlace">
                                </gmap-autocomplete>
                                <GmapMap
                                v-if="componentloader"
                                :center="mapCenter"
                                :zoom="zoom"
                                style="width: 100%; height: 300px"
                                >
                                <GmapMarker
                                    :position="markers.position"
                                    :clickable="true"
                                    :draggable="true"
                                    @dragend="gMapFunc($event.latLng)"
                                />
                                </GmapMap>
                            </v-col>
                        </v-row>
                  </v-sheet>
                </v-col>
                <v-col cols="12" md="12">
                  <v-sheet style="border: 5px solid black" class="pa-5" color="white lighten-3">
                        <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                            <span class="headline">Car Park Operation</span>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-divider class="my-4"></v-divider>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <vc-date-picker v-if="componentloader" v-model="form[40].range.start" mode="date" :masks="{ input: 'DD/MM/YYYY' }">
                                <template v-slot="{ inputValue, inputEvents, togglePopover }">
                                    <v-row dense>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field
                                                @click="togglePopover({ placement: 'top' })"
                                                :value="inputValue"
                                                @keyup="removeValStart"
                                                dense
                                                outlined
                                                hide-details="auto"
                                                background-color="white"
                                                label="Car Park Operation Start Date"
                                                :rules="form[40].rulestart"
                                                autocomplete="off"
                                                :disabled="readonly"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </template>
                                </vc-date-picker>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <vc-date-picker v-if="componentloader" v-model="form[40].range.end" mode="date" :masks="{ input: 'DD/MM/YYYY' }">
                                <template v-slot="{ inputValue, inputEvents, togglePopover }">
                                    <v-row dense>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field
                                                @click="togglePopover({ placement: 'top' })"
                                                :value="inputValue"
                                                @keyup="removeValEnd"
                                                dense
                                                outlined
                                                hide-details="auto"
                                                background-color="white"
                                                label="Car Park Operation End Date"
                                                :rules="form[40].ruleend"
                                                autocomplete="off"
                                                :disabled="readonly"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </template>
                                </vc-date-picker>
                            </v-col>
                        </v-row>
                  </v-sheet>
                </v-col>
                <v-col cols="12" md="12">
                  <v-sheet style="border: 5px solid black" class="pa-5" color="white lighten-3">
                        <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                            <span class="headline">Car Park Setting</span>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-divider class="my-4"></v-divider>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-autocomplete
                                    dense
                                    v-model="form[41].selected"
                                    :items="form[41].carparkcontracttype"
                                    :rules="form[41].rule"
                                    item-text="name"
                                    item-value="id"
                                    label="Select Contract Type"
                                    outlined
                                    :disabled="readonly"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-autocomplete
                                    dense
                                    v-model="form[42].selected"
                                    :items="form[42].carparklicenseapplicationcommencementdate"
                                    :rules="form[42].rule"
                                    item-text="name"
                                    item-value="id"
                                    label="Select Parking License Application Commencement Date"
                                    outlined
                                    :disabled="readonly"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12"><v-divider></v-divider></v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-layout row wrap>
                                <v-checkbox :disabled="readonly" multiple dense style="flex: 0 0 50%; max-width: 50%;" v-for="(item2, index2) of form[43].carparkoptions" hide-details :value="item2.id" v-model="form[43].selected" :key="index2">
                                    <template v-slot:label class="checkbox-role"><span style="width: 100%" @click="changeOrder">{{item2.name}}</span></template>
                                </v-checkbox>
                                </v-layout>
                            </v-col>
                            <v-col cols="12" sm="12" md="12"><v-divider class="my-4"></v-divider></v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                dense
                                outlined
                                v-model="form[44].carparktaxoption"
                                :rules="form[44].rule"
                                label="Car Park Tax Option"
                                :disabled="readonly"
                            ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                dense
                                outlined
                                v-model="form[45].carparktaxamount"
                                :rules="form[45].rule"
                                label="Car Park Tax Amount (RM)"
                                :disabled="readonly"
                            ></v-text-field>
                            </v-col>
                        </v-row>
                  </v-sheet>
                </v-col>
                <v-col cols="12" md="12">
                  <v-sheet style="border: 5px solid black" class="pa-5" color="white lighten-3">
                        <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                            <span class="headline">Car Park Fees</span>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-divider class="my-4"></v-divider>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                dense
                                outlined
                                v-model="form[46].carparkfeereactivation"
                                :rules="form[46].rule"
                                label="Reactivation Fee (RM)"
                                :disabled="readonly"
                            ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                dense
                                outlined
                                v-model="form[47].carparkfeelatepaymentpenalty"
                                :rules="form[47].rule"
                                label="Late Payment Penalty (RM)"
                                :disabled="readonly"
                            ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                dense
                                outlined
                                v-model="form[48].carparkfeereinstatement"
                                :rules="form[48].rule"
                                label="Reinstatement Fee (RM)"
                                :disabled="readonly"
                            ></v-text-field>
                            </v-col>
                        </v-row>
                  </v-sheet>
                </v-col>
                <v-col cols="12" md="12">
                  <v-sheet style="border: 5px solid black" class="pa-5" color="white lighten-3">
                        <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                            <span class="headline">General Document Setting</span>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-if="componentloader">
                                <v-file-input label="General Document Image" v-model="form[56].file" :rules="form[56].imgrule" show-size type="file" accept="image/*" :disabled="readonly"></v-file-input>
                                <v-card class="mb-6" style="height: 200px" outlined @drop.prevent="addDropFile56" @dragover.prevent>
                                    <v-overlay
                                    :absolute="true"
                                    opacity="1"
                                    color="grey"
                                    :value="form[56].image == '' || form[56].image == undefined ? true : false"
                                    >
                                    <v-row>
                                        <v-col>
                                            <v-icon x-large>fa fa-image</v-icon>
                                        </v-col>
                                    </v-row>
                                    </v-overlay>
                                    <img :src="form[56].image" :style="{width: '100%'}" />
                                    <v-overlay
                                    :absolute="true"
                                    :value="form[56].btn_loading"
                                    >
                                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                                    </v-overlay>
                                </v-card>
                            </v-col>
                        </v-row>
                  </v-sheet>
                </v-col>
            </v-row>
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn v-if="!readonly" tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-card>
    </v-form>
    <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
</v-dialog>
</v-row>
</template>
<script>
import alertbox from './Alert';
import TextEditor from '../TextEditor';
import * as moment from "moment";
import * as tz from "moment-timezone";

export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'allInfo', 'readonly'],
    
    data()  {
        return {
            dialog_local: false,
            innerModal: false,
            form: [ 
                {carparkaddresscountry: [], rule: [], selected: '' },
                {carparkname: '', rule: [] },
                {latitude: '', rule: [] },
                {longitude: '', rule: [] },
                {carparkoperationstarthour: '', rule: [] },
                {carparkoperationendhour: '', rule: [] },
                {carparktotalbaycount: '', rule: [] },
                {carparkcontactpersonname: '', rule: [] },
                {carparksoscontact1: '', rule: [] },
                {carparksoscontact2: '', rule: [] },
                {carparkcontactpersonemail: '', rule: [] },
                {carparkemail: '', rule: [] },
                {carparkweburl: '', rule: [] },
                {carparktelephoneno1: '', rule: [] },
                {carparktelephoneno2: '', rule: [] },
                {carparkfaxno: '', rule: [] },
                {carparkaddress1: '', rule: [] },
                {carparkaddress2: '', rule: [] },
                {carparkaddress3: '', rule: [] },
                {carparkaddresspostcode: '', rule: [], selected: '' },
                {carparkaddresscity: [], rule: [], selected: '' },
                {carparkaddressstate: [], rule: [], selected: '' },
                {carparktotalbayavailable: '', rule: [] },
                {carparkbillingcompanyname: '', rule: [] },
                {carparkbillingsstno: '', rule: [] },
                {carparkbillingregno: '', rule: [] },
                {carparkbillingaddress1: '', rule: [] },
                {carparkbillingaddress2: '', rule: [] },
                {carparkbillingaddress3: '', rule: [] },
                {carparkbillingaddresspostcode: '', rule: [] },
                {carparkbillingaddresscity: [], rule: [], selected: '' },
                {carparkbillingaddressstate: [], rule: [], selected: '' },
                {carparkbillingaddresscountry: [], rule: [], selected: '' },
                {carparkbillingtelephoneno: '', rule: [] },
                {carparkbillingfaxno: '', rule: [] },
                {carparkoperator: [], rule: [], selected: '' },
                {carparkbillingemail: '', rule: [] },
                {carparkbillingweburl: '', rule: [] },
                {carparkbillingtermsconditions: '', rule: [] },
                {carparklocation: '', rule: [] },
                {range: {start: '', end: ''}, rulestart: [], ruleend: [] }, //carparkoperation start date
                {carparkcontracttype: [{id: '', name: 'Select'}, {id: 'Tenancy Deal', name: 'Tenancy Deal'}, {id: 'Management Deal', name: 'Management Deal'}], rule: [], selected: '' },
                {carparklicenseapplicationcommencementdate: [
                    {id: '', name: 'Select'}, 
                    {id: 'Allow Half Month (1st & 16th)', name: 'Allow Half Month (1st & 16th)'}, 
                    {id: 'Allow Full Month Only (1st)', name: 'Allow Full Month Only (1st)'},
                    {id: 'Allow Any Day of Month', name: 'Allow Any Day of Month'},
                    ],
                    rule: [], selected: ''
                },
                {carparkoptions: [
                    {id: 'Enable Car Park', name: 'Enable Car Park'}, 
                    {id: 'Exemption from Late Payment', name: 'Exemption from Late Payment'}, 
                    {id: 'Exempted from SST', name: 'Exempted from SST'},
                    {id: 'ParkAide Enable (Force)', name: 'ParkAide Enable (Force)'},
                    {id: 'Suspend from Parking Application', name: 'Suspend from Parking Application'},
                    {id: 'Exemption from Stop Billing', name: 'Exemption from Stop Billing'},
                    {id: 'Car Park No SST', name: 'Car Park No SST'},
                    {id: 'ParkAide Enable (Optional)', name: 'ParkAide Enable (Optional)'},
                    ],
                    rule: [], selected: []
                },
                {carparktaxoption: '', rule: [] },
                {carparktaxamount: '', rule: [] },
                {carparkfeereactivation: '', rule: [] },
                {carparkfeelatepaymentpenalty: '', rule: [] },
                {carparkfeereinstatement: '', rule: [] },
                {carparkpaymentmethod: [
                    {id: 'Giro', name: 'Giro'}, 
                    {id: 'Cash (Site)', name: 'Cash (Site)'}, 
                    {id: 'Cheque (Site)', name: 'Cheque (Site)'},
                    {id: 'Online Banking', name: 'Online Banking'},
                    ],
                    rule: [], selected: []
                },
                {carparkactivestatus: false, rule: [] },
                {carparkmobiledisplaystatus: false, rule: [] },
                {carparkuserinputcode: '', rule: [] },
                {file: '', imgrule: [], image: '', btn_loading: '', logo: '' },
                {file: '', imgrule: [], image: '', btn_loading: '', header: '' },
                {file: '', imgrule: [], image: '', btn_loading: '', footer: '' },
                {file: '', imgrule: [], image: '', btn_loading: '', general: '' },
                {carparkdescription: '', rule: [] },
                {carparktype: [
                    {id: '', name: 'Select'}, 
                    {id: 'GATED', name: 'Gated'}, 
                    {id: 'STREET', name: 'Street'},
                    ],
                    rule: [], selected: []
                },
            ],

            roles: [],
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            map: null,
            mapCenter: {lat: 5.502994, lng: 101.700439},
            markers: {
            position: {
              lat: 5.502994,
              lng: 101.700439
            },
          },

          coordinates: null,
          zoom: 9,
          file: '',
          imgrule: [],
          image: '',
          modal_loader: false,
          btn_loading: false,
          componentloader: false,
          postcode: []
        }
    },

    components: {alertbox, 'text-editor': TextEditor},

    watch: {
        dialog: {
        handler: async function(value) {
            await this.modalHandler();
            this.componentloader = false;
            if(value == true){
                document.getElementsByClassName('v-dialog v-dialog--active')[0].scrollTop = 0;
                this.modal_loader = true;
                await this.handleValue();
                this.modal_loader = false;
                this.btn_loading = false;
                this.componentloader = true;
            }
        },
        },

        dialog_local: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },

        'markers.position': {
        handler: function(value) {
            this.form[2].latitude = value.lat;
            this.form[3].longitude = value.lng;
        },
        },
    },

    created(){
        /*
        this.$watch(() => this.form[0].carparkaddresscountry, this.onRuleChange);
        this.$watch(() => this.form[1].carparkname, this.onRuleChange);
        this.$watch(() => this.form[53].image, this.onRuleChange);
        this.$watch(() => this.form[54].image, this.onRuleChange);
        this.$watch(() => this.form[55].image, this.onRuleChange);
        this.$watch(() => this.form[56].image, this.onRuleChange);
        this.$watch(() => this.form[10].carparkcontactpersonemail, this.onRuleChange);
        this.$watch(() => this.form[11].carparkemail, this.onRuleChange);
        this.$watch(() => this.form[36].carparkbillingemail, this.onRuleChange);
        this.$watch(() => this.form[13].carparktelephoneno1, this.onRuleChange);
        this.$watch(() => this.form[14].carparktelephoneno2, this.onRuleChange);
        this.$watch(() => this.form[8].carparksoscontact1, this.onRuleChange);
        this.$watch(() => this.form[9].carparksoscontact2, this.onRuleChange);
        this.$watch(() => this.form[33].carparkbillingtelephoneno, this.onRuleChange);
        this.$watch(() => this.form[15].carparkfaxno, this.onRuleChange);
        this.$watch(() => this.form[34].carparkbillingfaxno, this.onRuleChange);
        this.$watch(() => this.form[12].carparkweburl, this.onRuleChange);
        this.$watch(() => this.form[37].carparkbillingweburl, this.onRuleChange);
        this.$watch(() => this.form[49].carparkpaymentmethod, this.onRuleChange);
        this.$watch(() => this.form[35].carparkoperator, this.onRuleChange);
    */
        this.$watch(() => this.form[53].file, this.handleImageMain, {deep: true});
        this.$watch(() => this.form[54].file, this.handleImageHeader, {deep: true});
        this.$watch(() => this.form[55].file, this.handleImageFooter, {deep: true});
        this.$watch(() => this.form[56].file, this.handleImageGeneral, {deep: true});
        //this.initMap();
    },

    methods: {
        async getAllCountry(){
            let list = [];
            try{
                let response = await this.$store.dispatch("listCountry", {search: ''});
                let dt = response.data.record;
                this.form[0].carparkaddresscountry.push({name: "Select Country", CountryCode: ""});
                this.form[32].carparkbillingaddresscountry.push({name: "Select Country", CountryCode: ""});
                if (response.data.code == "AP000"){
                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].CountryCode} - ${dt[i].CountryName}`;

                        this.form[0].carparkaddresscountry.push(dt[i]);
                        this.form[32].carparkbillingaddresscountry.push(dt[i]);
                    }
                }

                list = dt;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getAllOperator(){
            let list = [];
            try{
                let response = await this.$store.dispatch("listOperator", {search: ''});
                let dt = response.data.record;
                this.form[35].carparkoperator.push({name: "Select Operator", OperatorID: ""});
                if (response.data.code == "AP000"){
                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;

                        this.form[35].carparkoperator.push(dt[i]);
                    }
                }

                list = dt;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getAllState(){
            let list = [];
            try{
                let response = await this.$store.dispatch("listState", {search: ''});
                let dt = response.data.record;
                this.form[21].carparkaddressstate.push({name: "Select State", StateCode: ""});
                this.form[31].carparkbillingaddressstate.push({name: "Select State", StateCode: ""});
                if (response.data.code == "AP000"){
                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].StateCode} - ${dt[i].StateName}`;

                        this.form[21].carparkaddressstate.push(dt[i]);
                        this.form[31].carparkbillingaddressstate.push(dt[i]);
                    }
                }

                list = dt;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getAllCity(){
            let list = [];
            try{
                let response = await this.$store.dispatch("listCity", {search: ''});
                let dt = response.data.record;
                this.form[20].carparkaddresscity.push({name: "Select City", CityCode: ""});
                this.form[30].carparkbillingaddresscity.push({name: "Select City", CityCode: ""});
                if (response.data.code == "AP000"){
                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].CityCode} - ${dt[i].CityName}`;

                        this.form[20].carparkaddresscity.push(dt[i]);
                        this.form[30].carparkbillingaddresscity.push(dt[i]);
                    }
                }

                list = dt;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getAllPostcode(){
            let list = [];
            try{
                let response = await this.$store.dispatch("listPostcode", {search: ''});
                let dt = response.data.record;
                if (response.data.code == "AP000"){
                    this.postcode = dt;
                }

                list = dt;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        gMapFunc(evnt) {
            this.markers.position = {"lat":evnt.lat(), "lng":evnt.lng()};
        },

        setPlace(place) {
            if (!place) return
            console.log('t', place);
            this.form[39].carparklocation = place.formatted_address;
            this.zoom = 9;
            this.markers.position = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            };

            this.mapCenter = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            };

            this.zoom = 12;
        },

        setPlaceFromAPI(aLat, aLng) {
            if (!aLat) return

            this.zoom = 9;
            this.markers.position = {
            lat: Number(aLat),
            lng: Number(aLng),
            };

            this.mapCenter = {
            lat: Number(aLat),
            lng: Number(aLng),
            };

            this.zoom = 12;
        },

        initMap(){
            this.map = new google.maps.Map(document.getElementById('map'), {
            center: this.mapCenter,
            zoom: true,
            maxZoom: 20,
            minZoom: 3,
            streetViewControl: true,
            mapTypeControl: true,
            fullScreenControl: true,
            zoomControl: true
        });
        },

        onRuleChange(after, before) {
            this.form[0].rule = []; this.form[1].rule = []; this.form[53].rule = []; this.form[54].rule = []; this.form[55].rule = []; this.form[56].rule = [];
            this.form[10].rule = []; this.form[11].rule = []; this.form[36].rule = []; this.form[13].rule = []; this.form[14].rule = []; this.form[8].rule = [];
            this.form[9].rule = []; this.form[33].rule = []; this.form[15].rule = []; this.form[34].rule = []; this.form[12].rule = []; this.form[37].rule = [];
            this.form[49].rule = []; this.form[35].rule = []; this.form[46].rule = []; this.form[47].rule = []; this.form[48].rule = []; this.form[45].rule = [];
            this.form[19].rule = []; this.form[29].rule = []; this.form[40].rulestart = []; this.form[40].ruleend = []; this.form[58].rule = [];
        },

         getImageDimensions(file, w, h) {
            if (file != ''){
                let fact;
                return new Promise (function (resolved, rejected) {
                    var i = new Image()
                    i.onload = function(){
                        if (i.width !== w || i.height !== h){
                            fact = false;
                        }

                        else{
                            console.log('truth', 'true');
                            fact = true;
                        }

                        resolved(fact);
                    };

                    i.src = file;
                });
            }

            else{
                return true;
            }
        },

        async beforeCreateForm(flag){
            this.onRuleChange();
            console.log('pass0');
            let checklogo = await this.getImageDimensions(this.form[53].image, 400, 400);
            let checkheader = await this.getImageDimensions(this.form[54].image, 1000, 70);
            let checkfooter = await this.getImageDimensions(this.form[55].image, 1000, 150);
            let checkgeneral = await this.getImageDimensions(this.form[56].image, 1000, 70);

            this.form[35].rule = [
                v => !!v || 'Operator is required',
            ];

            this.form[1].rule = [
                v => !!v || 'This is required',
            ];

            this.form[53].imgrule = [ 
                //v => !v || v.size < 500000 || 'Image size should be less than 500 KB!',
                v => !v || /(jpe?g|png|gif|bmp)$/i.test(v.name.toString().split('.').pop().toLowerCase()) || 'Only common image format (PNG and JPEG/JPG) format accepted',
                v => !v || checklogo || 'Please ensure image is 400 x 400'
            ];

            this.form[54].imgrule = [
                //v => !v || v.size < 500000 || 'Image size should be less than 500 KB!',
                v => !v || /(jpe?g|png|gif|bmp)$/i.test(v.name.toString().split('.').pop().toLowerCase()) || 'Only common image format (PNG and JPEG/JPG) format accepted',
                v => !v || checkheader || 'Please ensure image is 1000 x 70'
            ];

            this.form[55].imgrule = [
                //v => !v || v.size < 500000 || 'Image size should be less than 500 KB!',
                v => !v || /(jpe?g|png|gif|bmp)$/i.test(v.name.toString().split('.').pop().toLowerCase()) || 'Only common image format (PNG and JPEG/JPG) format accepted',
                v => !v || checkfooter || 'Please ensure image is 1000 x 150'
            ];

            this.form[56].imgrule = [
                //v => !v || v.size < 500000 || 'Image size should be less than 500 KB!',
                v => !v || /(jpe?g|png|gif|bmp)$/i.test(v.name.toString().split('.').pop().toLowerCase()) || 'Only common image format (PNG and JPEG/JPG) format accepted',
                v => !v || checkgeneral || 'Please ensure image is 1000 x 70'
            ];

            this.form[10].rule = [
                v => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || 'Please enter a valid email address'
            ];

            this.form[11].rule = [
                v => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || 'Please enter a valid email address'
            ];

            this.form[36].rule = [
                v => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || 'Please enter a valid email address'
            ];

            this.form[13].rule = [
                v => !v || /^(\+?6?0)[0-9]{1,2}-*[0-9]{7,8}$/.test(v) || 'Please enter a valid phone no.'
            ];

            this.form[14].rule = [
                v => !v || /^(\+?6?0)[0-9]{1,2}-*[0-9]{7,8}$/.test(v) || 'Please enter a valid phone no.'
            ];

            this.form[8].rule = [
                v => !v || /^(\+?6?0)[0-9]{1,2}-*[0-9]{7,8}$/.test(v) || 'Please enter a valid phone no.'
            ];

            this.form[9].rule = [
                v => !v || /^(\+?6?0)[0-9]{1,2}-*[0-9]{7,8}$/.test(v) || 'Please enter a valid phone no.'
            ];

            this.form[33].rule = [
                v => !v || /^(\+?6?0)[0-9]{1,2}-*[0-9]{7,8}$/.test(v) || 'Please enter a valid phone no.'
            ];

            this.form[15].rule = [
                v => !v || /^(\+?6?0)[0-9]{1,2}-*[0-9]{7,8}$/.test(v) || 'Please enter a valid fax no.'
            ];

            this.form[34].rule = [
                v => !v || /^(\+?6?0)[0-9]{1,2}-*[0-9]{7,8}$/.test(v) || 'Please enter a valid fax no.'
            ];

            this.form[12].rule = [
                v => !v || /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(v) || "URL must be valid"
            ];

            this.form[37].rule = [
                v => !v || /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(v) || "URL must be valid"
            ];

            this.form[46].rule = [
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form[47].rule = [
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form[48].rule = [
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form[45].rule = [
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form[19].rule = [
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form[29].rule = [
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form[58].rule = [
                v => !!v || 'Car Park Type is required',
            ];

            if (this.form[40].range.end != '' && this.form[40].range.hasOwnProperty('end')){
                console.log('endtruth', this.form[40].range.end != '' && this.form[40].range.hasOwnProperty('end'));
                console.log('endtruth1', this.form[40].range.end);
                console.log('endtruth2', this.form[40].range.hasOwnProperty('end'));
                this.form[40].rulestart = [
                    v => moment(moment(this.form[40].range.start).format('YYYY-MM-DD')).isBefore(moment(this.form[40].range.end).format('YYYY-MM-DD')) && this.form[40].range.end != '' || 'Date should be before end date'
                ];
            }

            if (this.form[40].range.start != '' && this.form[40].range.hasOwnProperty('start')){
                this.form[40].ruleend = [
                    v => !moment(moment(this.form[40].range.end).format('YYYY-MM-DD')).isBefore(moment(this.form[40].range.start).format('YYYY-MM-DD')) || 'Date should be after start date',
                    v => this.form[40].range.start != '' || 'There should be a start date'
                ];
            }

/*
            this.form[49].rule = [
                v => !!v || 'Payment method is required',
            ];
*/
            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                    console.log('pass1');
                }
            });
        },

        modalHandler(){
            this.dialog_local = !this.dialog_local;
        },

        modalCloseHandler(){
            if (this.dialog_local == false){
                this.$emit('closed', false);
            }
        },

        async handleValue(){
            //for inserting char into string
            String.prototype.insert = function(what, index) {
                return index > 0
                    ? this.replace(new RegExp('.{' + index + '}'), '$&' + what)
                    : what + this;
            };

            this.getAllCountry();
            this.getAllOperator();
            this.getAllState();
            await this.getAllCity();
            await this.getAllPostcode();
            this.onRuleChange();
            this.form[35].selected = this.modalInfo.OperatorID;
            this.form[1].carparkname = this.modalInfo.CarParkName;
            this.form[39].carparklocation = this.modalInfo.CarParkLocation;
            this.form[2].latitude = this.modalInfo.CarParkLocationGPSLatitude;
            this.form[3].longitude = this.modalInfo.CarParkLocationGPSLongitude;
            this.form[16].carparkaddress1 = this.modalInfo.CarParkAddress1;
            this.form[17].carparkaddress2 = this.modalInfo.CarParkAddress2;
            this.form[18].carparkaddress3 = this.modalInfo.CarParkAddress3;
            this.form[19].carparkaddresspostcode = this.modalInfo.CarParkAddressPostcode;
            this.form[20].selected = this.modalInfo.CarParkAddressCity;
            this.form[21].selected = this.modalInfo.CarParkAddressState;
            this.form[0].selected = this.modalInfo.CarParkAddressCountry;
            this.form[13].carparktelephoneno1 = this.modalInfo.CarParkTelephoneNo1;
            this.form[14].carparktelephoneno2 = this.modalInfo.CarParkTelephoneNo2;
            this.form[15].carparkfaxno = this.modalInfo.CarParkFaxNo;
            this.form[11].carparkemail = this.modalInfo.CarParkEmail;
            this.form[12].carparkweburl = this.modalInfo.CarParkWebURL;
            this.form[7].carparkcontactpersonname = this.modalInfo.CarParkContactPersonName;
            this.form[10].carparkcontactpersonemail = this.modalInfo.CarParkContactPersonEmail;
            this.form[8].carparksoscontact1 = this.modalInfo.CarParkSOSContact1;
            this.form[9].carparksoscontact2 = this.modalInfo.CarParkSOSContact2;
            this.form[23].carparkbillingcompanyname = this.modalInfo.CarParkBillingCompanyName;
            this.form[25].carparkbillingregno = this.modalInfo.CarParkBillingRegNo;
            this.form[24].carparkbillingsstno = this.modalInfo.CarParkBillingSSTNo;
            this.form[26].carparkbillingaddress1 = this.modalInfo.CarParkBillingAddress1;
            this.form[27].carparkbillingaddress2 = this.modalInfo.CarParkBillingAddress2;
            this.form[28].carparkbillingaddress3 = this.modalInfo.CarParkBillingAddress3;
            this.form[29].carparkbillingaddresspostcode = this.modalInfo.CarParkBillingAddressPostcode;
            this.form[30].selected = this.modalInfo.CarParkBillingAddressCity;
            this.form[31].selected = this.modalInfo.CarParkBillingAddressState;
            this.form[32].selected = this.modalInfo.CarParkBillingAddressCountry;
            this.form[33].carparkbillingtelephoneno = this.modalInfo.CarParkBillingTelephoneNo;
            this.form[34].carparkbillingfaxno = this.modalInfo.CarParkBillingFaxNo;
            this.form[36].carparkbillingemail = this.modalInfo.CarParkBillingEmail;
            this.form[37].carparkbillingweburl = this.modalInfo.CarParkBillingWebURL;
            this.form[38].carparkbillingtermsconditions = this.modalInfo.CarParkBillingTermsConditions;
            this.form[6].carparktotalbaycount = this.modalInfo.CarParkTotalBayCount;
            this.form[22].carparktotalbayavailable = this.modalInfo.CarParkTotalBayAvailable;
            this.form[4].carparkoperationstarthour = this.modalInfo.CarParkOperationStartHour.insert(':', 2);
            this.form[5].carparkoperationendhour = this.modalInfo.CarParkOperationEndHour.insert(':', 2);
            this.form[40].range = {};
            this.form[40].range.start = this.modalInfo.CarParkOperationStartDate;
            this.form[40].range.end = this.modalInfo.CarParkOperationEndDate;
            this.form[41].selected = this.modalInfo.CarParkContractType;
            this.form[42].selected = this.modalInfo.CarParkLicenseApplicationCommencementDate;
            this.form[44].carparktaxoption = this.modalInfo.CarParkTaxOptions;
            this.form[45].carparktaxamount = this.modalInfo.CarParkTaxAmount;
            this.form[57].carparkdescription = this.modalInfo.CarParkDescription;

            this.form[49].selected = [];

            if (this.modalInfo.CarParkPaymentMethod !== ''){
                let len = this.modalInfo.CarParkPaymentMethod.split(',').length;
                let arr = this.modalInfo.CarParkPaymentMethod.split(',');

                for (let i=0; i<len; i++){
                    console.log(arr);
                    this.form[49].selected.push({id: arr[i], name: arr[i]});
                }
            }

            this.form[43].selected = this.modalInfo.CarParkOptions.split(',');
            this.form[46].carparkfeereactivation = this.modalInfo.CarParkFeeReactivation;
            this.form[47].carparkfeelatepaymentpenalty = this.modalInfo.CarParkFeeLatePaymentPenalty;
            this.form[48].carparkfeereinstatement = this.modalInfo.CarParkFeeReinstatement;
            //this.form[49].selected = this.modalInfo.CarParkPaymentMethod.split(',');
            this.form[53].image = this.modalInfo.CarParkLogoImage;
            this.form[54].image = this.modalInfo.CarParkHeaderImage;
            this.form[55].image = this.modalInfo.CarParkFooterImage;
            this.form[56].image = this.modalInfo.CarParkGeneralDocumentImage;
            this.form[53].file = '';
            this.form[54].file = '';
            this.form[55].file = '';
            this.form[56].file = '';
            this.form[52].carparkuserinputcode = this.modalInfo.CarParkUserInputCode;
            this.form[50].carparkactivestatus = this.modalInfo.ActiveStatus;
            this.form[51].carparkmobiledisplaystatus = this.modalInfo.MobileDisplayStatus;

            this.form[53].btn_loading = false;
            this.form[54].btn_loading = false;
            this.form[55].btn_loading = false;
            this.form[56].btn_loading = false;
            this.form[58].selected = this.modalInfo.CarParkType == null ? '' : this.modalInfo.CarParkType;

            this.setPlaceFromAPI(this.modalInfo.CarParkLocationGPSLatitude, this.modalInfo.CarParkLocationGPSLongitude);
        },

        //set the action to be sent to watcher to execute the create/edit function
        onConfirm(value){
          console.log(value);
                if (this.ma.modal_action_type == 'edit'){
                    this.form.flag = 'update';
                    this.$emit('confirm', this.form);

                    if (this.form[53].file != ''){
                        this.$emit('confirmLogo', this.form);
                    }

                    if (this.form[54].file != ''){
                        this.$emit('confirmHeader', this.form);
                    }

                    if (this.form[55].file != ''){
                        this.$emit('confirmFooter', this.form);
                    }

                    if (this.form[56].file != ''){
                        this.$emit('confirmGeneral', this.form);
                    }
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },

        async handleImageMain(v) {
            //console.log(v.name.toString().split('.').pop().toLowerCase())
            console.log('img', v);

            if (v){
            this.form[53].btn_loading = true;
            const selectedImage = v; //get first file
            let base64img = await this.getBase64(selectedImage);
            if (base64img == undefined) base64img = '';

            this.form[53].image = base64img;
            this.form[53].btn_loading = false;
            let check = await this.getImageDimensions(this.form[53].image, 400, 400);
            if (!check){
                this.form[53].image = await this.modalInfo.CarParkLogoImage;
                this.form[53].file = '';
                this.form[53].imgrule = [
                    v => false || 'Please ensure image is 400 x 400'
                ];
                console.log(this.form[53].imgrule);
            }

            else{
                this.form[53].imgrule = [];
            }
            }

            else{
                await this.$nextTick(async () => {
                this.form[53].image = await this.modalInfo.CarParkLogoImage;
                this.form[53].btn_loading = false;
                });
            }
        },

        async handleImageHeader(v) {
            //console.log(v.name.toString().split('.').pop().toLowerCase())
            console.log('img', v);

            if (v){
            this.form[54].btn_loading = true;
            const selectedImage = v; //get first file
            let base64img = await this.getBase64(selectedImage);
            if (base64img == undefined) base64img = '';

            this.form[54].image = base64img;
            this.form[54].btn_loading = false;
            let check = await this.getImageDimensions(this.form[54].image, 1000, 70);
            if (!check){
                this.form[54].image = await this.modalInfo.CarParkHeaderImage;
                this.form[54].file = '';
                this.form[54].imgrule = [
                    v => false || 'Please ensure image is 1000 x 70'
                ];
                console.log(this.form[54].imgrule);
            }

            else{
                this.form[54].imgrule = [];
            }
            }

            else{
                await this.$nextTick(async () => {
                this.form[54].image = await this.modalInfo.CarParkHeaderImage;
                this.form[54].btn_loading = false;
                });
            }
        },

        async handleImageFooter(v) {
            //console.log(v.name.toString().split('.').pop().toLowerCase())
            console.log('img', v);

            if (v){
            this.form[55].btn_loading = true;
            const selectedImage = v; //get first file
            let base64img = await this.getBase64(selectedImage);
            if (base64img == undefined) base64img = '';

            this.form[55].image = base64img;
            this.form[55].btn_loading = false;
            let check = await this.getImageDimensions(this.form[55].image, 1000, 150);
            if (!check){
                this.form[55].image = await this.modalInfo.CarParkFooterImage;
                this.form[55].file = '';
                this.form[55].imgrule = [
                    v => false || 'Please ensure image is 1000 x 150'
                ];
                console.log(this.form[55].imgrule);
            }

            else{
                this.form[55].imgrule = [];
            }
            }

            else{
                await this.$nextTick(async () => {
                this.form[55].image = await this.modalInfo.CarParkFooterImage;
                this.form[55].btn_loading = false;
                });
            }
        },

        async handleImageGeneral(v) {
            //console.log(v.name.toString().split('.').pop().toLowerCase())
            console.log('img', v);

            if (v){
            this.form[56].btn_loading = true;
            const selectedImage = v; //get first file
            let base64img = await this.getBase64(selectedImage);
            if (base64img == undefined) base64img = '';

            this.form[56].image = base64img;
            this.form[56].btn_loading = false;
            let check = await this.getImageDimensions(this.form[56].image, 1000, 70);
            if (!check){
                this.form[56].image = await this.modalInfo.CarParkGeneralDocumentImage;
                this.form[56].file = '';
                this.form[56].imgrule = [
                    v => false || 'Please ensure image is 1000 x 70'
                ];
                console.log(this.form[56].imgrule);
            }

            else{
                this.form[56].imgrule = [];
            }
            }

            else{
                await this.$nextTick(async () => {
                this.form[56].image = await this.modalInfo.CarParkGeneralDocumentImage;
                this.form[56].btn_loading = false;
                });
            }
        },

        getBase64(file) {
            if (file){
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                });
            }
        },

        execFilter(val){
            if (val == 'state1'){
                let a = this.form[20].carparkaddresscity.find(i => i.CityCode == this.form[20].selected);
                this.form[0].selected = a.CountryCode;
                this.form[21].selected = a.StateCode;
            }

            if (val == 'state2'){
                let a = this.form[30].carparkbillingaddresscity.find(i => i.CityCode == this.form[30].selected);
                this.form[32].selected = a.CountryCode;
                this.form[31].selected = a.StateCode;
            }

            if (val == 'postcode1'){
                let a = this.postcode.find(i => i.Postcode == this.form[19].carparkaddresspostcode);
                console.log(a);
                if (a != undefined){
                    this.form[0].selected = a.CountryCode;
                    this.form[21].selected = a.StateCode;
                    this.form[20].selected = a.CityCode;
                }

                else{
                    this.form[0].selected = '';
                    this.form[21].selected = '';
                    this.form[20].selected = '';
                }
            }

            if (val == 'postcode2'){
                let a = this.postcode.find(i => i.Postcode == this.form[29].carparkbillingaddresspostcode);
                console.log(a);
                if (a != undefined){
                    this.form[32].selected = a.CountryCode;
                    this.form[31].selected = a.StateCode;
                    this.form[30].selected = a.CityCode;
                }

                else{
                    this.form[32].selected = '';
                    this.form[31].selected = '';
                    this.form[30].selected = '';
                }
            }
        },

        addDropFile53(e) { if (!this.readonly) this.form[53].file = e.dataTransfer.files[0]; },
        addDropFile54(e) { if (!this.readonly) this.form[54].file = e.dataTransfer.files[0]; },
        addDropFile55(e) { if (!this.readonly) this.form[55].file = e.dataTransfer.files[0]; },
        addDropFile56(e) { if (!this.readonly) this.form[56].file = e.dataTransfer.files[0]; },

        changeOrder(e) {
            e.preventDefault();
            e.stopPropagation();
        },

        removeValStart(e){
            e.preventDefault();
            e.stopPropagation();
            this.form[40].range.start = '';
        },

        removeValEnd(e){
            e.preventDefault();
            e.stopPropagation();
            this.form[40].range.end = '';
        }
    }
}
</script>