<template>
    <div>
        <span v-for="(it, id) in swal.scolor" :key="id">
        <v-alert dense v-if="it=='success'" :type="it" dismissible>
            <ul v-for="(item, index) in swal.message" :key="index">
                <li>{{item}}</li>
            </ul>
        </v-alert>
        <v-alert dense v-if="it=='error'" :type="it" dismissible>
            <ul v-for="(item, index) in swal.emessage" :key="index">
                <li>{{item}}</li>
            </ul>
        </v-alert>
        </span>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
        <v-card-title>Car Park</v-card-title>
        <v-container fluid>
            <v-row>
                <v-btn-toggle class="col-12 col-md-6 offset-md-6" style="background: none">
                    <v-btn small disabled>
                        <v-icon small>fa fa-search</v-icon>
                    </v-btn>
                    <v-text-field placeholder="Search" class="search-textfield" hide-details outlined dense v-model="search"></v-text-field>
                </v-btn-toggle>
                <v-col cols="12" md="12">
                    <v-autocomplete
                            dense
                            outlined
                            placeholder="All Systems"
                            label="System"
                            v-model="system.selected"
                            @change="execFilter('system')"
                            :items="system.items"
                            item-text="name"
                            item-value="SystemID"
                            :rules="system.rule"
                            :loading="system.loader"
                            :disabled="system.disable"
                            hide-details
                            >
                            </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                            dense
                            outlined
                            placeholder="All Operators"
                            label="Operator"
                            v-model="operator.selected"
                            @change="execFilter('operator')"
                            :items="operator.items"
                            item-text="name"
                            item-value="OperatorID"
                            :rules="operator.rule"
                            :loading="operator.loader"
                            :disabled="operator.disable"
                            hide-details
                            >
                            </v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-pagination
                    v-model="page"
                    :length.sync="pages"
                    :total-visible="6"
                    class="mt-4"
                    style="float: left !important"
                    ></v-pagination><br/><p class="text-caption" style="margin-left: 10px">Page {{page}} of {{pages}}</p>
                </v-col>
                <v-col cols="12" md="6"><v-btn v-if="validateAccess('carpark_add')" :loading="btn_loading" :disabled="btn_loading" tile type="submit" small color="primary" @click.prevent="createItem()"><v-icon left x-small dark>fa fa-cogs</v-icon>Create Car Park
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col><span class="text-body-2">View {{page > 1 ? (page - 1) * 100 + 1 : 1}} - {{getCount}} of {{totalrecords}}</span></v-col>
                <v-col cols="12" md="12" id="table-parent">
                    <div id="wrapper1">
                        <div id="div1">
                        </div>
                    </div>
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                        sort-by="CreatedDateTime"
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>{{props.item.CarParkAddressCity_Name}}</td>
                            <td>{{props.item.OperatorID}}</td>
                            <td>{{props.item.OperatorName}}</td>
                            <td>{{props.item.CarParkID}}</td>
                            <td>
                                <span v-if="validateAccess('carpark_mapping')">
                                <span v-if="props.item.CarParkMappingID">
                                <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                <a v-on="on" style="text-decoration: underline" id="custom-hover" @click.prevent="viewMore(props.item, 'create mapping id')">{{props.item.CarParkMappingID}}</a>
                                </template>
                                <span>Update Mapping ID</span>
                                </v-tooltip>
                                </span>
                                <span v-else>
                                <v-btn text depressed x-small color="blue" @click.prevent="viewMore(props.item, 'create mapping id')"><v-icon x-small left>fa fa-plus-square</v-icon> Add</v-btn>
                                </span>
                                </span>
                                <span v-else>
                                    <span v-if="props.item.CarParkMappingID">
                                    {{props.item.CarParkMappingID}}
                                    </span>
                                    <span v-else>
                                    N/A
                                    </span>
                                </span>
                            </td>
                            <td>{{props.item.CarParkUserInputCode}}</td>
                            <td>
                                <v-tooltip top v-if="validateAccess('carpark_update')">
                                <template v-slot:activator="{ on }">
                                    <a v-on="on" style="text-decoration: underline" id="custom-hover" @click.prevent="updateItem(props.item, 'update')">{{props.item.CarParkName}}</a>
                                </template>
                                <span>Update Car Park</span>
                                </v-tooltip>
                                <v-tooltip top v-else>
                                <template v-slot:activator="{ on }">
                                    <a v-on="on" style="text-decoration: underline" id="custom-hover" @click.prevent="updateItem(props.item, 'view')">{{props.item.CarParkName}}</a>
                                </template>
                                <span>View Car Park</span>
                                </v-tooltip>
                            </td>
                            <td>{{props.item.CarParkType}}</td>
                            <td>{{props.item.CarParkTaxAmount}}</td>
                            <!--
                            <td>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on">{{props.item.BaysCount}} <a id="custom-hover" @click.prevent="viewMore(props.item, 'add')"><v-icon color="blue" x-small>fa fa-plus</v-icon></a></span>
                                    </template>
                                    <span>Add Bays</span>
                                </v-tooltip>
                                <v-tooltip top v-if="props.item.Bays.length > 0">
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on">
                                        <a id="custom-hover" class="ml-2" @click.prevent="viewMore(props.item, 'remove')"><v-icon color="red" x-small>fa fa-minus</v-icon></a>
                                        </span>
                                    </template>
                                    <span>Remove Bays</span>
                                </v-tooltip>
                            </td>
                            -->
                            <td>{{props.item.CreatedDateTime}}</td>
                            <td>
                                <v-chip
                                x-small
                                class="ma-2"
                                :color="statusCheck(props.item.MobileDisplayStatus, 'color')"
                                text-color="white">
                                    {{statusCheck(props.item.MobileDisplayStatus, 'text')}}
                                </v-chip>
                            </td>
                            <td>
                                <v-chip
                                x-small
                                class="ma-2"
                                :color="statusCheck(props.item.ActiveStatus, 'color')"
                                text-color="white">
                                    {{statusCheck(props.item.ActiveStatus, 'text')}}
                                </v-chip>
                            </td>
                        </tr>
                    </template>
                    <!--
                    <template v-slot:item.CarParkName="{ item }">
                        <v-tooltip top>
                        <template v-slot:activator="{ on }">
                        <a v-on="on" style="text-decoration: underline" id="custom-hover" @click.prevent="viewMore(item, 'update')">{{item.CarParkName}}</a>
                        </template>
                        <span>Update Operator</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.CarParkMappingID="{ item }">
                        <span v-if="item.CarParkMappingID">
                        <v-tooltip top>
                        <template v-slot:activator="{ on }">
                        <a v-on="on" style="text-decoration: underline" id="custom-hover" @click.prevent="viewMore(item, 'create mapping id')">{{item.CarParkMappingID}}</a>
                        </template>
                        <span>Update Mapping ID</span>
                        </v-tooltip>
                        </span>
                        <span v-else>
                        <v-tooltip top>
                        <template v-slot:activator="{ on }">
                        <v-btn text depressed small v-on="on" color="blue" @click.prevent="viewMore(item, 'create mapping id')"><v-icon x-small>fa fa-plus-square</v-icon></v-btn>
                        </template>
                        <span>Create Mapping ID</span>
                        </v-tooltip>
                        </span>
                    </template>
                    -->
                    <!--
                    <template v-slot:item.action="{ item }">
                        <v-tooltip top>
                        <template v-slot:activator="{ on }">
                        <span>
                            <a v-on="on" id="custom-hover" @click.prevent="viewMore(item, 'delete')"><v-icon color="red" right x-small>fa fa-trash</v-icon></a>
                        </span>
                        </template>
                        <span>Remove Country</span>
                        </v-tooltip>
                    </template>
                    -->
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <!--
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            <ul v-for="(item, index) in swal.message" :key="index">
                <li>{{item}}</li>
            </ul>
        </v-snackbar>
        -->
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></create>
        <edit :dialog="ma.modal_update" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :readonly="ma.readonly" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm"
        @confirmLogo="onConfirmLogo"
        @confirmHeader="onConfirmHeader"
        @confirmFooter="onConfirmFooter"
        @confirmGeneral="onConfirmGeneral"
         @closed="onClosed"></edit>
        <addbays :dialog="ma.modal_addbays" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></addbays>
        <removebays :dialog="ma.modal_removebays" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></removebays>
        <createmapping :dialog="ma.modal_mapping" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></createmapping>
        <alert :dialog="ma.modal_status" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alert>
        <alertbox2 :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox2>
    </div>
</template>
<script>
import timer from '../../assets/js/sleeper';
import create from '.././modals/CreateCarpark';
import edit from '.././modals/UpdateCarpark';
import createmapping from '.././modals/CreateCarparkMapping';
import addbays from '.././modals/AddBays';
import removebays from '.././modals/RemoveBays';
import alert from '.././modals/Alert';
import {ERR} from '../../assets/js/errhandle.js';
import alertbox2 from '../modals/Notification';
import * as moment from "moment";
import * as tz from "moment-timezone";
import {rule} from '../../assets/js/checkPerm';
import * as AppStorage from '../../assets/js/storage';

export default {
    name: 'Car Park',
    title: 'Car Park',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Car Park',
                disabled: true,
                href: '#',
                },

                {
                text: 'Car Park',
                disabled: true,
                href: '#',
                },
            ],
            items: [],
            search: '',
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'City', value: 'CarParkAddressCity_Name', width: '8%' },
                { text: 'CPO ID', value: 'OperatorID', width: '8%' },
                { text: 'Operator', value: 'OperatorName', width: '8%' },
                { text: 'CP ID', value: 'CarParkID', width: '5%' },
                { text: 'CP ID Ref.', value: 'CarParkMappingID', width: '5%' },
                { text: 'CP Code', value: 'CarParkUserInputCode', width: '5%' },
                { text: 'Car Park', value: 'CarParkName', width: '10%' },
                { text: 'Type', value: 'CarParkType', width: '5%' },
                { text: 'SST (%)', value: 'CarParkTaxAmount', width: '3%' },
                //{ text: 'Bays Managed', value: 'BaysCount', width: '5%' },
                { text: 'Created Date', value: 'CreatedDateTime', width: '10%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Display', value: 'MobileDisplayStatus', width: '3%' },
                { text: 'Status', value: 'ActiveStatus', width: '3%' },
            ],
            tableloading: false,
            btn_loading: false,
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: [],
                emessage: [],
                scolor: [],
            },
            swal2: {
                notification: false,
                message: [],
                scolor: [],
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                modal_status: false,
                modal_update: false,
                modal_mapping: false,
                readonly: true,
                allInfo: [],

                modal_addbays: false,
                modal_removebays: false
            },

            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },
            system: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            operator: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },

            services: [],
            totalrecords: ''
        }
    },

    components: {create, edit, alert, alertbox2, createmapping, addbays, removebays},

    async created(){
        await this.getSystemFull();
        await this.getOperatorFull();
        await this.getUpdateCarPark();
        window.addEventListener("unload", (evt) => this.onUnload({system: this.system.selected, operator: this.operator.selected}, evt));
        this.system.selected = JSON.parse(AppStorage.get('filter')).system;
        this.operator.selected = JSON.parse(AppStorage.get('filter')).operator;
        this.getCarpark(1);
        this.horizontalScroll();
    },

    watch: {
        page: {
        handler: function(value) {
            this.items = [];
            this.getCarpark();
        }
        },

        'search': {
        handler: function(value) {
            this.searchTimeOut();
        }
        },
    },

    computed: {
        getCount(){
            if (this.items.length > 0)
                return this.items[this.items.length - 1].row;

                else{
                    return '';
                }
            },
    },

    methods: {
        async getCarpark(pg){
            this.items = [];
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("listCarpark", {search: this.search, systemid: this.system.selected, operatorid: this.operator.selected, resultpage: pg > 0 ? this.page = pg : this.page, sortingcolumn: 'CreatedDateTime'});
                let response2 = await this.$store.dispatch("getAllSystem2", {search: ''});
                let response3 = await this.$store.dispatch("listOperator", {search: ''});
                let response4 = await this.$store.dispatch("viewAllCarParkBay", {});
                let recordCount = response.data.recordcount[0].resultcount;
                this.totalrecords = recordCount;

                this.services = response;
                await timer.sleep(100);
                if (response.data.code == 'AP000'){
                let dt = response.data.record;
                let recordCount = response.data.recordcount[0].resultcount;

                for(var i=0; i<dt.length; i++){
                    let sy = response2.data.record.find(item => item.SystemID == dt[i].SystemID);
                    let op = response3.data.record.find(item => item.OperatorID == dt[i].OperatorID);
                    let bays = response4.data.record.filter(item => item.CarParkID == dt[i].CarParkID);

                    dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                    dt[i].CreatedDateTime = moment(new Date(dt[i].CreatedDateTime)).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].UpdatedDateTime = dt[i].UpdatedDateTime == null ? "N/A" : moment(new Date(dt[i].UpdatedDateTime)).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].SystemName = sy.SystemName;
                    dt[i].OperatorName = op.OperatorName;
                    dt[i].Bays = bays;
                    dt[i].BaysCount = bays.length;
                    dt[i].sstpercentage = dt[i].CarParkTaxAmount * 100;
                    dt[i].CarParkTaxAmount = dt[i].CarParkTaxAmount.toString();
                    dt[i].CarParkAddressCity_Name = dt[i].CarParkAddressCity_Name == null ? "N/A" : dt[i].CarParkAddressCity_Name;
                    //replace(/\s/g,'') remove any literal whitespace
                }

                this.items = dt;
                this.pages = Math.ceil(recordCount / 100);
                this.tableloading = false;
                }
            }

            catch(err){
                console.log(err);
            }
        },

        async getUpdateCarPark(){
            if (this.$route.query.updated === true){
                console.log('updated');
                let v = JSON.parse(this.$route.query.v);
                let cp = this.operator.itemsFull.find(item => item.OperatorID == v[35].selected);
                this.system.selected = cp.SystemID;
                this.execFilter('systemafterupdate');
                this.operator.selected = v[35].selected;
                this.operatorUpdate(v);
                if (this.$route.query.logo == 'yes') this.logoUpdate(v);
                if (this.$route.query.header == 'yes') this.headerUpdate(v);
                if (this.$route.query.footer == 'yes') this.footerUpdate(v);
                this.$router.replace({'query': null});
            }

            if (this.$route.query.created === true){
                console.log('created');
                let v = JSON.parse(this.$route.query.v);
                await this.addOperator(v);
                this.$router.replace({'query': null});
            }

            //if (this.$route.query.id)
                //this.$router.replace({'query': null});
        },

        async getSystemFull(){
            let list = [];
            this.system.items = [];
            try{
                this.system.loader = true;
                this.system.disable = true;
                let response = await this.$store.dispatch("getAllSystem2", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.system.items.push({name: "All Systems", SystemID: ""});
                    this.system.itemsFull.push({name: "All Systems", SystemID: ""});
                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].SystemID} - ${dt[i].SystemName}`;
                        this.system.items.push({name: dt[i].name, SystemID: dt[i].SystemID});
                        this.system.itemsFull.push({name: dt[i].name, SystemID: dt[i].SystemID});
                    }
                }

                //this.system.itemsFull = dt;

                this.system.selected = this.system.items[0].SystemID;

                this.system.loader = false;
                this.system.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getOperatorFull(){
            let list = [];
            this.operator.items = [];
            this.operator.selected = "";

            try{
                this.operator.loader = true;
                this.operator.disable = true;

                let self = this;
                if (this.system.selected){
                    let operator = this.operator.itemsFull.filter(item => {
                    return item.SystemID == self.system.selected;
                    });

                    this.operator.items.push({name: "All Operators", OperatorID: ""});

                    for(var i=0; i<operator.length; i++){
                        operator[i].name = `${operator[i].OperatorID} - ${operator[i].OperatorName}`;
                        this.operator.items.push({name: operator[i].name, OperatorID: operator[i].OperatorID});
                    }

                    //this.operator.selected = this.operator.items[1].OperatorID;
                }

                else{
                    let response = await this.$store.dispatch("listOperator", {search: ''});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        this.operator.items.push({name: "All Operators", OperatorID: ""});
                        this.operator.itemsFull.push({name: "All Operators", OperatorID: ""});

                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;
                        this.operator.items.push({name: dt[i].name, OperatorID: dt[i].OperatorID});
                        this.operator.itemsFull.push(dt[i]);
                    }

                    this.operator.selected = this.operator.items[0].OperatorID;
                    }
                }

                //this.operator.itemsFull = dt;

                this.operator.loader = false;
                this.operator.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        async addOperator(v){
            this.btn_loading = true;
            this.tableloading = true;
            let rangetruth = true;
            let rangetruthstart = true;
            let rangetruthend = true;
            if (v[40].range === null){ rangetruth = false}
            if (this.isObjEmpty(v[40].range)){ rangetruth = false}
            if ((rangetruth && v[40].range.start == '') || typeof v[40].range.start == 'undefined' || v[40].range.start == null){ rangetruthstart = false}
            if ((rangetruth && v[40].range.end == '') || typeof v[40].range.end == 'undefined' || v[40].range.end == null){ rangetruthend = false}
            
            try{
                //this.handleSnackbarMessage('success');
                let req = {
                    "operatorid": v[35].selected,
                    "carparkname": v[1].carparkname,
                    "carparklocation": v[39].carparklocation,
                    "carparkdescription": v[57].carparkdescription,
                    "carparklocationgpslatitude": v[2].latitude,
                    "carparklocationgpslongitude": v[3].longitude,
                    "carparkaddress1": v[16].carparkaddress1,
                    "carparkaddress2": v[17].carparkaddress2,
                    "carparkaddress3": v[18].carparkaddress3,
                    "carparkaddresspostcode": v[19].carparkaddresspostcode,
                    "carparkaddresscity": v[20].selected,
                    "carparkaddressstate": v[21].selected,
                    "carparkaddresscountry": v[0].selected,
                    "carparktelephoneno1": v[13].carparktelephoneno1,
                    "carparktelephoneno2": v[14].carparktelephoneno2,
                    "carparkfaxno": v[15].carparkfaxno,
                    "carparkemail": v[11].carparkemail,
                    "carparkweburl": v[12].carparkweburl,
                    "carparkcontactpersonname": v[7].carparkcontactpersonname,
                    "carparkcontactpersonemail": v[10].carparkcontactpersonemail,
                    "carparksoscontact1": v[8].carparksoscontact1,
                    "carparksoscontact2": v[9].carparksoscontact2,
                    "carparkbillingcompanyname": v[23].carparkbillingcompanyname,
                    "carparkbillingregno": v[25].carparkbillingregno,
                    "carparkbillingsstno": v[24].carparkbillingsstno,
                    "carparkbillingaddress1": v[26].carparkbillingaddress1,
                    "carparkbillingaddress2": v[27].carparkbillingaddress2,
                    "carparkbillingaddress3": v[28].carparkbillingaddress3,
                    "carparkbillingaddresspostcode": v[29].carparkbillingaddresspostcode,
                    "carparkbillingaddresscity": v[30].selected,
                    "carparkbillingaddressstate": v[31].selected,
                    "carparkbillingaddresscountry": v[32].selected,
                    "carparkbillingtelephoneno": v[33].carparkbillingtelephoneno,
                    "carparkbillingfaxno": v[34].carparkbillingfaxno,
                    "carparkbillingemail": v[36].carparkbillingemail,
                    "carparkbillingweburl": v[37].carparkbillingweburl,
                    "carparkbillingtermsconditions": v[38].carparkbillingtermsconditions,
                    "carparktotalbaycount": v[6].carparktotalbaycount,
                    "carparktotalbayavailable": v[22].carparktotalbayavailable,
                    "carparkoperationstarthour": moment(v[4].carparkoperationstarthour,'hh:mm a').format('HHmm'),
                    "carparkoperationendhour": moment(v[5].carparkoperationendhour,'hh:mm a').format('HHmm'),
                    "carparkoperationstartdate": rangetruthstart ? moment(v[40].range.start).format('YYYY-MM-DD') : "",
                    "carparkoperationenddate": rangetruthend ? moment(v[40].range.end).format('YYYY-MM-DD') : "",
                    "carparkcontracttype": v[41].selected,
                    "carparklicenseapplicationcommencementdate": v[42].selected,
                    "carparktaxoption": v[44].selected,
                    "carparktaxamount": v[45].carparktaxamount,
                    "carparkoptions": v[43].selected != '' ? v[43].selected.join() : '',
                    "carparkfeereactivation": v[46].carparkfeereactivation,
                    "carparkfeelatepaymentpenalty": v[47].carparkfeelatepaymentpenalty,
                    "carparkfeereinstatement": v[48].carparkfeereinstatement,
                    "carparkpaymentmethod": v[49].selected != '' ? v[49].selected.map(function(elem){
                                                return elem.name;
                                            }).join(",") : '',
                    "carparklogoimage": v[53].image,
                    "carparkheaderimage": v[54].image,
                    "carparkfooterimage": v[55].image,
                    "carparkgeneraldocumentimage": v[56].image,
                    "carparkuserinputcode": v[52].carparkuserinputcode,
                    "carparkactivestatus": v[50].carparkactivestatus == true ? '1' : '0',
                    "carparkmobiledisplaystatus": v[51].carparkmobiledisplaystatus == true ? '1' : '0',
                    "carparktype": v[58].selected,
                    "carparkmanagedbaycount": v[62].carparkmanagedbaycount,
                    "carparkwheellockstatus": v[63].carparkwheellockstatus == true ? '1' : '0',
                    "carparkbayprefix": v[64].bayprefix,
                    "carparklprwebstatus": v[65].carparklprwebstatus,
                    "carparkwebpaymentlprurl": v[66].carparkwebpaymentlprurl,
                    "carparkwlwebstatus": v[67].carparkwlwebstatus,
                    "carparkwebpaymentwlurl": v[68].carparkwebpaymentwlurl
                };

                let response = await this.$store.dispatch("createCarpark", req);
                await timer.sleep(800);
                if (response.data.code === 'AP000'){
                    await this.getCarpark();
                    this.$vuetify.goTo(0);
                    this.swal.notification = true;
                    this.handleSnackbarMessage('success');
                    this.swal.message.push( `${ERR.HANDLE(response.data.code)}: Car Park Created!`);
                }
                else if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.handleSnackbarMessage('error');
                    this.swal.emessage.push( `${ERR.HANDLE(response.data.code)}: This Car Park is already created`);
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.handleSnackbarMessage('error');
                    this.swal.emessage.push( `${ERR.HANDLE(response.data.code)}: Car Park Create`);
                }

                this.tableloading = false;
                this.btn_loading = false;
                
            }

            catch(err){
                this.swal.notification = true;
                this.handleSnackbarMessage('error');
                this.swal.emessage.push('Something went wrong. Please contact tech team');
                this.tableloading = false;
                this.btn_loading = false;
            }
        },

        async operatorRemove(){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("deleteOperator", {operatorid: this.ma.modalInfo.OperatorID})
                if (response.data.code === 'AP000'){
                        await this.getCarpark();

                        this.swal.notification = true;
                        this.handleSnackbarMessage('success');
                        this.swal.message.push( `${ERR.HANDLE(response.data.code)}: Operator has been removed!`);
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.handleSnackbarMessage('error');
                        this.swal.emessage.push( `${ERR.HANDLE(response.data.code)}: Unable to remove. Operator is currently being used!`);
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.handleSnackbarMessage('error');
                    this.swal.emessage.push( `${ERR.HANDLE(response.data.code)}`);
                }

                this.tableloading = false;
            }

            catch(err){
                this.handleSnackbarMessage('error');
                this.swal.notification = true;
                this.swal.emessage.push( "Something went wrong. Please try again later");
                this.tableloading = false;
            }
        },

        async updateMapping(v){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("updateMappingID", {carparkid: this.ma.modalInfo.CarParkID, carparkmappingid: v[1].carparkmappingid})
                if (response.data.code === 'AP000'){
                        await this.getCarpark();

                        this.swal.notification = true;
                        this.handleSnackbarMessage('success');
                        this.swal.message.push( `${ERR.HANDLE(response.data.code)}: Mapping ID has been updated!`);
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.handleSnackbarMessage('error');
                        this.swal.emessage.push( `${ERR.HANDLE(response.data.code)}: Unable to update. Mapping ID is currently being used!`);
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.handleSnackbarMessage('error');
                    this.swal.emessage.push( `${ERR.HANDLE(response.data.code)}: Mapping ID`);
                }

                this.tableloading = false;
            }

            catch(err){
                this.handleSnackbarMessage('error');
                this.swal.notification = true;
                this.swal.emessage.push('Something went wrong. Please contact tech team');
                this.tableloading = false;
            }
        },

        async operatorUpdate(v){
            this.tableloading = true;
            let rangetruth = true;
            let rangetruthstart = true;
            let rangetruthend = true;
            if (v[40].range === null){ rangetruth = false}
            if (this.isObjEmpty(v[40].range)){ rangetruth = false}
            if ((rangetruth && v[40].range.start == '') || typeof v[40].range.start == 'undefined' || v[40].range.start == null){ rangetruthstart = false}
            if ((rangetruth && v[40].range.end == '') || typeof v[40].range.end == 'undefined' || v[40].range.end == null){ rangetruthend = false}

            try{
                let req = {
                    "carparkid": this.$route.query.id,
                    "operatorid": v[35].selected,
                    "carparkname": v[1].carparkname,
                    "carparklocation": v[39].carparklocation,
                    "carparkdescription": v[57].carparkdescription,
                    "carparklocationgpslatitude": v[2].latitude,
                    "carparklocationgpslongitude": v[3].longitude,
                    "carparkaddress1": v[16].carparkaddress1,
                    "carparkaddress2": v[17].carparkaddress2,
                    "carparkaddress3": v[18].carparkaddress3,
                    "carparkaddresspostcode": v[19].carparkaddresspostcode,
                    "carparkaddresscity": v[20].selected,
                    "carparkaddressstate": v[21].selected,
                    "carparkaddresscountry": v[0].selected,
                    "carparktelephoneno1": v[13].carparktelephoneno1,
                    "carparktelephoneno2": v[14].carparktelephoneno2,
                    "carparkfaxno": v[15].carparkfaxno,
                    "carparkemail": v[11].carparkemail,
                    "carparkweburl": v[12].carparkweburl,
                    "carparkcontactpersonname": v[7].carparkcontactpersonname,
                    "carparkcontactpersonemail": v[10].carparkcontactpersonemail,
                    "carparksoscontact1": v[8].carparksoscontact1,
                    "carparksoscontact2": v[9].carparksoscontact2,
                    "carparkbillingcompanyname": v[23].carparkbillingcompanyname,
                    "carparkbillingregno": v[25].carparkbillingregno,
                    "carparkbillingsstno": v[24].carparkbillingsstno,
                    "carparkbillingaddress1": v[26].carparkbillingaddress1,
                    "carparkbillingaddress2": v[27].carparkbillingaddress2,
                    "carparkbillingaddress3": v[28].carparkbillingaddress3,
                    "carparkbillingaddresspostcode": v[29].carparkbillingaddresspostcode,
                    "carparkbillingaddresscity": v[30].selected,
                    "carparkbillingaddressstate": v[31].selected,
                    "carparkbillingaddresscountry": v[32].selected,
                    "carparkbillingtelephoneno": v[33].carparkbillingtelephoneno,
                    "carparkbillingfaxno": v[34].carparkbillingfaxno,
                    "carparkbillingemail": v[36].carparkbillingemail,
                    "carparkbillingweburl": v[37].carparkbillingweburl,
                    "carparkbillingtermsconditions": v[38].carparkbillingtermsconditions,
                    "carparktotalbaycount": v[6].carparktotalbaycount,
                    "carparktotalbayavailable": v[22].carparktotalbayavailable,
                    "carparkoperationstarthour": moment(v[4].carparkoperationstarthour,'hh:mm a').format('HHmm'),
                    "carparkoperationendhour": moment(v[5].carparkoperationendhour,'hh:mm a').format('HHmm'),
                    "carparkoperationstartdate": rangetruthstart ? moment(v[40].range.start).format('YYYY-MM-DD') : "",
                    "carparkoperationenddate": rangetruthend ? moment(v[40].range.end).format('YYYY-MM-DD') : "",
                    "carparkcontracttype": v[41].selected,
                    "carparklicenseapplicationcommencementdate": v[42].selected,
                    "carparktaxoption": v[44].selected,
                    "carparktaxamount": v[45].carparktaxamount,
                    "carparkoptions": v[43].selected != '' ? v[43].selected.join() : '',
                    "carparkfeereactivation": v[46].carparkfeereactivation,
                    "carparkfeelatepaymentpenalty": v[47].carparkfeelatepaymentpenalty,
                    "carparkfeereinstatement": v[48].carparkfeereinstatement,
                    "carparkpaymentmethod": v[49].selected != '' ? v[49].selected.map(function(elem){
                                                return elem.name;
                                            }).join(",") : '',
                    "carparkuserinputcode": v[52].carparkuserinputcode,
                    "carparkactivestatus": v[50].carparkactivestatus == true ? '1' : '0',
                    "carparkmobiledisplaystatus": v[51].carparkmobiledisplaystatus == true ? '1' : '0',
                    "carparktype": v[58].selected,
                    "carparkmanagedbaycount": v[62].carparkmanagedbaycount,
                    "carparkwheellockstatus": v[63].carparkwheellockstatus == true ? '1' : '0'
                };

                let response = await this.$store.dispatch("updateCarpark", req)
                await this.$store.dispatch("updateCarParkPrefix", {carparkid: req.carparkid, bayprefix: v[64].bayprefix !== null && v[64].bayprefix !== '' ? v[64].bayprefix : null})
                if (response.data.code === 'AP000'){
                        await this.getCarpark();
                        this.$vuetify.goTo(0);

                        this.swal.notification = true;
                        this.handleSnackbarMessage('success');
                        this.swal.message.push(`${ERR.HANDLE(response.data.code)}: Car Park has been updated!`);
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.handleSnackbarMessage('error');
                        this.swal.emessage.push(`${ERR.HANDLE(response.data.code)}: Unable to update. Car Park is currently being used!`);
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.handleSnackbarMessage('error');
                    this.swal.emessage.push( `${ERR.HANDLE(response.data.code)}: Car Park Update`);
                }

                this.tableloading = false;
            }

            catch(err){
                console.log('rrr', err);
                this.handleSnackbarMessage('error');
                this.swal.notification = true;
                this.swal.emessage.push('Something went wrong. Please contact tech team');
                this.tableloading = false;
                this.btn_loading = false;
            }
        },

        async addBay(v){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("createBays", {carparkid: v.carparkid, baynameid: v.baynameid, baytype: v.baytype, baylatitude: v.baylat, baylongitude: v.baylong})
                if (response.data.code === 'AP000'){
                        //await this.getCarpark();

                        this.swal.notification = true;
                        this.handleSnackbarMessage('success');
                        this.swal.message.push( `${ERR.HANDLE(response.data.code)}: Bays has been created!`);
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.handleSnackbarMessage('error');
                        this.swal.emessage.push( `${ERR.HANDLE(response.data.code)}: Unable to create.`);
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.handleSnackbarMessage('error');
                    this.swal.emessage.push( `${ERR.HANDLE(response.data.code)}`);
                }

                this.tableloading = false;
            }

            catch(err){
                this.handleSnackbarMessage('error');
                this.swal.notification = true;
                this.swal.emessage.push('Something went wrong. Please contact tech team');
                this.tableloading = false;
            }
        },

        async editBay(v){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("editBays", {carparkid: v.carparkid, carparkbayid: v.bayid, baynameid: v.baynameid, baytype: v.baytype, baylatitude: v.baylat, baylongitude: v.baylong})
                if (response.data.code === 'AP000'){
                        //await this.getCarpark();

                        this.swal.notification = true;
                        this.handleSnackbarMessage('success');
                        this.swal.message.push( `${ERR.HANDLE(response.data.code)}: Bays has been added/updated!`);
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.handleSnackbarMessage('error');
                        this.swal.emessage.push( `${ERR.HANDLE(response.data.code)}: Unable to add/update.`);
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.handleSnackbarMessage('error');
                    this.swal.emessage.push( `${ERR.HANDLE(response.data.code)}`);
                }

                this.tableloading = false;
            }

            catch(err){
                this.handleSnackbarMessage('error');
                this.swal.notification = true;
                this.swal.emessage.push('Something went wrong. Please contact tech team');
                this.tableloading = false;
            }
        },

        async removeBay(v){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("removeBays", {carparkid: v.carparkid, carparkbayid: v.bayid})
                if (response.data.code === 'AP000'){
                        //await this.getCarpark();

                        this.swal.notification = true;
                        this.handleSnackbarMessage('success');
                        this.swal.message.push( `${ERR.HANDLE(response.data.code)}: Bays has been deleted!`);
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.handleSnackbarMessage('error');
                        this.swal.emessage.push( `${ERR.HANDLE(response.data.code)}: Unable to delete.`);
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.handleSnackbarMessage('error');
                    this.swal.emessage.push( `${ERR.HANDLE(response.data.code)}`);
                }

                this.tableloading = false;
            }

            catch(err){
                this.handleSnackbarMessage('error');
                this.swal.notification = true;
                this.swal.emessage.push('Something went wrong. Please contact tech team');
                this.tableloading = false;
            }
        },

        async handleSnackbarMessage(type, message){
            if (type == 'success'){
                if (!this.swal.scolor.includes('success')){
                    this.swal.scolor.push('success');
                }
            }

            if (type == 'error'){
                if (!this.swal.scolor.includes('error')){
                    this.swal.scolor.push('error');
                }
            }

            await timer.sleep(4000);
            this.swal.scolor = [];
        },

        async logoUpdate(v){
            try{
                this.tableloading = true;
                let req = {
                    "carparkid": this.$route.query.id,
                    "carparklogo": v[53].image,
                };

                let response = await this.$store.dispatch("updateLogoImage", req)
                if (response.data.code === 'AP000'){
                        await this.getCarpark();

                        this.swal.notification = true;
                        this.handleSnackbarMessage('success');
                        this.swal.message.push(`${ERR.HANDLE(response.data.code)}: Car Park Logo has been updated!`);
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.handleSnackbarMessage('error');
                        this.swal.emessage.push(`${ERR.HANDLE(response.data.code)}: Unable to update. Car Park Logo is currently being used!`);
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.handleSnackbarMessage('error');
                    this.swal.emessage.push( `${ERR.HANDLE(response.data.code)}: Car Park Logo`);
                }

                this.tableloading = false;
            }

            catch(err){
                this.handleSnackbarMessage('error');
                this.swal.notification = true;
                this.swal.emessage.push( "Something went wrong. Please try again later");
                this.tableloading = false;
            }
        },

        async headerUpdate(v){
            try{
                this.tableloading = true;
                let req = {
                    "carparkid": this.$route.query.id,
                    "carparkheader": v[54].image,
                };

                let response = await this.$store.dispatch("updateHeaderImage", req)
                if (response.data.code === 'AP000'){
                        await this.getCarpark();

                        this.swal.notification = true;
                        this.handleSnackbarMessage('success');
                        this.swal.message.push( `${ERR.HANDLE(response.data.code)}: Car Park Header has been updated!`);
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.handleSnackbarMessage('error');
                        this.swal.emessage.push( `${ERR.HANDLE(response.data.code)}: Unable to update. Car Park Header is currently being used!`);
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.handleSnackbarMessage('error');
                    this.swal.emessage.push( `${ERR.HANDLE(response.data.code)}: Car Park Header`);
                }

                this.tableloading = false;
            }

            catch(err){
                this.handleSnackbarMessage('error');
                this.swal.notification = true;
                this.swal.emessage.push( "Something went wrong. Please try again later");
                this.tableloading = false;
            }
        },

        async footerUpdate(v){
            try{
                this.tableloading = true;
                let req = {
                    "carparkid": this.$route.query.id,
                    "carparkfooter": v[55].image,
                };

                let response = await this.$store.dispatch("updateFooterImage", req)
                if (response.data.code === 'AP000'){
                        await this.getCarpark();

                        this.swal.notification = true;
                        this.handleSnackbarMessage('success');
                        this.swal.message.push( `${ERR.HANDLE(response.data.code)}: Car Park Footer has been updated!`);
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.handleSnackbarMessage('error');
                        this.swal.emessage.push( `${ERR.HANDLE(response.data.code)}: Unable to update. Car Park Footer is currently being used!`);
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.handleSnackbarMessage('error');
                    this.swal.emessage.push( `${ERR.HANDLE(response.data.code)}: Car Park Footer`);
                }

                this.tableloading = false;
            }

            catch(err){
                this.handleSnackbarMessage('error');
                this.swal.notification = true;
                this.swal.emessage.push( "Something went wrong. Please try again later");
                this.tableloading = false;
            }
        },

        async generalUpdate(v){
            try{
                this.tableloading = true;
                let req = {
                    "carparkid": this.ma.modalInfo.CarParkID,
                    "carparkgeneraldocument": v[56].image,
                };

                let response = await this.$store.dispatch("updateGeneralImage", req)
                if (response.data.code === 'AP000'){
                        await this.getCarpark();

                        this.swal.notification = true;
                        this.handleSnackbarMessage('success');
                        this.swal.message.push( `${ERR.HANDLE(response.data.code)}: Car Park General Document Logo has been updated!`);
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.handleSnackbarMessage('error');
                        this.swal.emessage.push( `${ERR.HANDLE(response.data.code)}: Unable to update. Car Park General Document Logo is currently being used!`);
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.handleSnackbarMessage('error');
                    this.swal.emessage.push( `${ERR.HANDLE(response.data.code)}: Car Park General Document`);
                }

                this.tableloading = false;
            }

            catch(err){
                this.handleSnackbarMessage('error');
                this.swal.notification = true;
                this.swal.emessage.push( "Something went wrong. Please try again later");
                this.tableloading = false;
            }
        },

        updateItem(row){
            this.ma.modalInfo = row;
            this.ma.allInfo = this.items;
            this.$router.push({ name: "Update Car Park", params: {id: row.CarParkID}, query: {row: JSON.stringify(row)}});
        },

        createItem(){
            this.$router.push({ name: "Create Car Park"});
        },

        viewMore(row, flag){
            //this.autoRefresh = 0;
            if (flag == 'delete'){
                this.ma.modal_status = true;
                this.ma.modalInfo = row;
                this.swal.message = [];
                this.swal.emessage = [];
                this.swal.scolor = [];
                this.swal.notification = false;
            }

            if (flag == 'create'){
                this.ma.modal = true;
                this.ma.allInfo = this.items;
                this.swal.message = [];
                this.swal.emessage = [];
                this.swal.scolor = [];
                this.swal.notification = false;
            }

            if (flag == 'create mapping id'){
                this.ma.modal_mapping = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.swal.message = [];
                this.swal.emessage = [];
                this.swal.scolor = [];
                this.swal.notification = false;
            }

            if (flag == 'update'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.swal.message = [];
                this.swal.emessage = [];
                this.swal.scolor = [];
                this.swal.notification = false;
                this.ma.readonly = false;
            }

            if (flag == 'view'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.swal.message = [];
                this.swal.emessage = [];
                this.swal.scolor = [];
                this.swal.notification = false;
                this.ma.readonly = true;
            }

            if (flag == 'add'){
                this.ma.modal_addbays = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.swal.message = [];
                this.swal.emessage = [];
                this.swal.scolor = [];
                this.swal.notification = false;
            }

            if (flag == 'remove'){
                this.ma.modal_removebays = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.swal.message = [];
                this.swal.emessage = [];
                this.swal.scolor = [];
                this.swal.notification = false;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        onClosed(value){
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_status = value;
                this.ma.modal_update = value;
                this.ma.modal_mapping = value;
                this.ex.modal = value;
                this.ma.modal_addbays = value;
                this.ma.modal_removebays = value;
            }
        },

        async onConfirm(value){
            if (value.flag === 'create'){
                this.ma.confirmCreate = true;
                this.ma.modal = false;
                this.addOperator(value);
            }

            if (value.flag === 'update'){
                this.ma.modal_update = false;
                //this.operatorUpdate(value);
            }

            if (value.flag === 'create mapping id'){
                this.ma.confirmCreate = true;
                this.ma.modal_mapping = false;
                this.updateMapping(value);
            }

            if (value.flag === 'addbay'){
                this.ma.confirmCreate = true;
                this.ma.modal_addbays = false;

                let items = JSON.parse(JSON.stringify(value));

                for(let [index, item] of items.entries()){
                    let baynameid = item[1].baynameid; let baytype = item[2].baytype;
                    let baylat = item[3].baylatitude; let baylong = item[4].baylongitude;

                    let itemsforapi = {carparkid: this.ma.modalInfo.CarParkID, baynameid: baynameid, baytype: baytype, baylat: baylat, baylong: baylong};
                    await this.addBay(itemsforapi);
                }

                await this.getCarpark();
                //this.updateMapping(value);
            }

            if (value.flag === 'editbay'){
                this.ma.confirmCreate = true;
                this.ma.modal_addbays = false;

                let newitems = JSON.parse(JSON.stringify(value.newArr));
                let olditems = JSON.parse(JSON.stringify(value.oldArr));

                let baynameidtruth = 0; let baytypetruth = 0;
                let baylattruth = 0; let baylongtruth = 0;

                for(let [index, item] of olditems.entries()){
                    let baynameid = item[1].baynameid; let baytype = item[2].baytype;
                    let baylat = item[3].baylatitude; let baylong = item[4].baylongitude;

                    baynameidtruth += item[1].baynameid == this.ma.modalInfo.Bays[index].BayNameID ? 0 : 1;
                    baytypetruth += item[2].baytype == this.ma.modalInfo.Bays[index].BayType ? 0 : 1;
                    baylattruth += item[3].baylatitude == this.ma.modalInfo.Bays[index].BayLatitude ? 0 : 1;
                    baylongtruth += item[4].baylongitude == this.ma.modalInfo.Bays[index].BayLongitude ? 0 : 1;
                }

                let checkifoldvalueschanged = baynameidtruth + baytypetruth + baylattruth + baylongtruth;

                if (checkifoldvalueschanged > 0){
                    for(let [index, item] of olditems.entries()){
                        let baynameid = item[1].baynameid; let baytype = item[2].baytype;
                        let baylat = item[3].baylatitude; let baylong = item[4].baylongitude;
                        let bayid = item[5].bayid;

                        let itemsforapi = {carparkid: this.ma.modalInfo.CarParkID, baynameid: baynameid, baytype: baytype, baylat: baylat, baylong: baylong, bayid: bayid};
                        await this.editBay(itemsforapi);
                    }
                }

                for(let [index, item] of newitems.entries()){
                    let baynameid = item[1].baynameid; let baytype = item[2].baytype;
                    let baylat = item[3].baylatitude; let baylong = item[4].baylongitude;
                    let bayid = item[5].bayid;

                    let itemsforapi = {carparkid: this.ma.modalInfo.CarParkID, baynameid: baynameid, baytype: baytype, baylat: baylat, baylong: baylong, bayid: bayid};
                    await this.addBay(itemsforapi);
                }

                await this.getCarpark();

                /*
                for(let [index, item] of items.entries()){
                    let baynameid = item[1].baynameid; let baytype = item[2].baytype;
                    let baylat = item[3].baylatitude; let baylong = item[4].baylongitude;

                    let itemsforapi = {carparkid: this.ma.modalInfo.CarParkID, baynameid: baynameid, baytype: baytype, baylat: baylat, baylong: baylong};
                    console.log('itemsforapi', itemsforapi);
                    this.addBay(itemsforapi);
                }
                */
                //this.updateMapping(value);
            }

            if (value === true){
                if (this.ma.modal_action_type == 'delete')
                {
                    this.ma.modal_status = false;
                    this.operatorRemove();
                }
            }

            if (value.flag === 'removebay'){
                this.ma.confirmCreate = true;
                this.ma.modal_removebays = false;
                
                let items = JSON.parse(JSON.stringify(value));

                for(let [index, item] of items.entries()){
                    let bayid = item[5].bayid;
                    let rmItem = item[6].rmItem;

                    if (rmItem == true){
                        let itemsforapi = {carparkid: this.ma.modalInfo.CarParkID, bayid: bayid};
                        await this.removeBay(itemsforapi);
                    }
                }

                await this.getCarpark();
            }
        },

        onConfirmLogo(value){
            this.logoUpdate(value);
        },

        onConfirmHeader(value){
            this.headerUpdate(value);
        },

        onConfirmFooter(value){
            this.footerUpdate(value);
        },

        onConfirmGeneral(value){
            this.generalUpdate(value);
        },

        async execFilter(type){
            if (type == 'system'){
                await this.getOperatorFull();
                await this.searchTimeOut();
            }

            if (type == 'systemafterupdate'){
                await this.getOperatorFull();
            }

            if (type == 'operator'){
                await this.searchTimeOut();
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.getCarpark(1);
          }, 800);
        },
        
        isObjEmpty(obj) {
        for(var prop in obj) {
            if(obj.hasOwnProperty(prop)) {
            return false;
            }
        }

        return JSON.stringify(obj) === JSON.stringify({});
        },

        validateArr(arr){
            let truth = arr.some(item => {
                return item === true;
            });

            return truth;
        },

        statusCheck(val, typ){
            if (typ == 'text'){
                    if (val === 1){ return 'Enabled'}
                    else if (val === 0){return 'Disabled'}
                    else {return 'null'}
            }

            if (typ == 'color'){
                    if (val === 1){ return 'green'}
                    else if (val === 0){return 'red'}
                    else {return 'grey'}
            }
        },

        onUnload(filter, evt){
            AppStorage.set('filter', JSON.stringify(filter));
        },

        horizontalScroll(){
            var wrapper1 = document.getElementById('wrapper1');
            var wrapper2 = document.querySelector('.v-data-table__wrapper');
            document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
            document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;

            if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";

            window.addEventListener('resize', function() {
                document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
                document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;

                if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";
            });

            const myObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
                document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;
                console.log('table', document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth);
                console.log('parent', document.getElementById("table-parent").offsetWidth);

                if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";
            });
            });

            const someEl = document.getElementsByTagName("table")[0];
            myObserver.observe(someEl);


            wrapper1.onscroll = function() {
            wrapper2.scrollLeft = wrapper1.scrollLeft;
            };
            wrapper2.onscroll = function() {
            wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }
    }
}
</script>