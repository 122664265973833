<template>
<v-row justify="center">
<v-dialog v-model="dialog_local" max-width="650">
    <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('remove')" lazy-validation>
        <v-card class="mx-auto" tile>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
            Remove Car Park Bays
            <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
            </v-card-title>
        <v-card-text>
        <v-container class="fill-height"
        fluid >
            <v-row>
                <v-col cols="4">
                    <v-checkbox
                    v-model="select_all"
                    @change="selectAll"
                    label="Select All"
                    hide-details="auto"
                    ></v-checkbox>
                </v-col>
                <v-col offset="6" style="place-self: flex-end;">
                    <span>Remove</span>
                </v-col>
            </v-row>
            <v-row v-for="(item, index) in form" :key="index">
                <v-col cols="12" md="6">
                    <v-text-field
                        dense
                        outlined
                        type="text"
                        v-model="item[1].baynameid"
                        :rules="item[1].rule"
                        label="Bay Name ID"
                        required
                        hide-details="auto"
                        autocomplete="off"
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        dense
                        outlined
                        type="text"
                        v-model="item[2].baytype"
                        :rules="item[2].rule"
                        label="Bay Type"
                        required
                        hide-details="auto"
                        autocomplete="off"
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                    <v-checkbox
                    v-model="item[6].rmItem"
                    ></v-checkbox>
                </v-col>
            </v-row>
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-card>
    </v-form>
    <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
</v-dialog>
</v-row>
</template>
<script>
import alertbox from './Alert';
import _ from 'lodash';

export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'allInfo'],
    
    data()  {
        return {
            dialog_local: false,
            innerModal: false,
            form: [ 
                [
                {carparkid: '', rule: [] },
                {baynameid: '', rule: [] },
                {baytype: '', rule: [] },
                {baylatitude: '', rule: [] },
                {baylongitude: '', rule: [] },
                {bayid: '' },
                {rmItem: false },
                ]
            ],

            roles: [],
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            total: 1,
            extractedNumFromString: 1,
            selectIteratingName: false,
            select_all: false
        }
    },

    components: {alertbox},

    watch: {
        dialog: {
        handler: async function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialog_local: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    created(){
        //this.$watch(() => this.form[0].systemid, this.onRuleChange);
        //this.$watch(() => this.form[1].systemname, this.onRuleChange);
    },

    methods: {
        async checkRef(val){
            if (val != undefined){
                let system = await this.allInfo;
                let check = system.some((item) => {
                    return item.SystemID.toLowerCase() == val.toLowerCase();
                });

                return check;
            }

            else{
                return '';
            }
        },

        selectAll(){
            for(let [index, item] of this.form.entries()){
                let rmItem = item[6].rmItem;

                if (this.select_all === true){
                    item[6].rmItem = true;
                }

                else{
                    item[6].rmItem = false;
                }
            }
        },

        onRuleChange(after, before) {
            //this.form[0].rule = [];
            //this.form[1].rule = [];
        },

        async beforeCreateForm(flag){
            for (var i=0; i<this.form.length; i++){
                this.form[i][1].rule = [
                    v => !!v || 'Bay Name is required',
                ];

                this.form[i][2].rule = [
                    v => !!v || 'Bay Type is required',
                ];

                this.form[i][3].rule = [
                    v => !v || !isNaN(v) || 'Only number is allowed'
                ];

                this.form[i][4].rule = [
                    v => !v || !isNaN(v) || 'Only number is allowed'
                ];
            }

            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        modalHandler(){
            this.dialog_local = !this.dialog_local;
        },

        modalCloseHandler(){
            if (this.dialog_local == false){
                this.$emit('closed', false);
            }
        },

        handleValue(){
            if (this.modalInfo.Bays.length == 0){
                this.form = [ 
                    [
                    {carparkid: '', rule: [] },
                    {baynameid: '', rule: [] },
                    {baytype: '', rule: [] },
                    {baylatitude: '', rule: [] },
                    {baylongitude: '', rule: [] },
                    {bayid: ''},
                    {rmItem: false}
                    ]
                ];
                this.total = 1;
                this.extractedNumFromString = 1;
                this.selectIteratingName = false;
            }

            else{
                this.form = [];
                for(let i=0; i<this.modalInfo.Bays.length; i++){
                    this.form.push([
                        {carparkid: '', rule: []},
                        {baynameid: this.modalInfo.Bays[i].BayNameID, rule: []},
                        {baytype: this.modalInfo.Bays[i].BayType, rule: []},
                        {baylatitude: this.modalInfo.Bays[i].BayLatitude, rule: []},
                        {baylongitude: this.modalInfo.Bays[i].BayLongitude, rule: []},
                        {bayid: this.modalInfo.Bays[i].BayID},
                        {rmItem: false}
                    ]);
                }
                this.total = this.modalInfo.Bays.length;
                this.extractedNumFromString = 1;
                this.selectIteratingName = false;
            }

            this.select_all = false;
            //this.form[0].systemid = '';
            //this.form[1].systemname = '';
        },

        //set the action to be sent to watcher to execute the create/edit function
        onConfirm(value){
          //console.log(value);
                if (this.ma.modal_action_type == 'remove'){
                    this.form.flag = 'removebay';
                    this.$emit('confirm', this.form);
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },
    }
}
</script>